import React from 'react'

function ML({ t }) {
    return (
        <div id="ML">
            <h1>{t("Mentions légales")}</h1>

            <p><strong>{t("Hébergement du site")} :</strong> OVHcloud.</p>
            <p><strong>{t("Responsable de la publication")} :</strong> Alexandre FRAZER.</p>
            <p><strong>{t("Adresse email")} :</strong> bet.set.match.bsm@gmail.com</p>
            <p><strong>{t("N° SIREN")} :</strong> 984317883.</p>
            <p><strong>{t("Code NAF")} :</strong> 5829C.</p>
        </div>
    )
}

export default ML
