import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Legend, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { stylizedBookName } from "../utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Legend, Title);

const booksColor = {
    "BetClic": "#5c4b51",
    "PSEL": "#8cbeb2",
    "WinAMax": "#f3b562",
    "UniBet": "#ac0303",
    "ZeBet": "#eba000",
    "PMU": "#f06060",
    "NetBet": "#f2ebbf",
    "FrancePari": "#f2ebbf",
    "GenyBet": "#f2ebbf",
    "FeelingBet": "#f2ebbf",
    "Bwin": "#02e890",

    "Stake": "#f5ef03",

    "MyStake": "#03bdf5",
    "FreshBet": "#03bdf5",
    "31Bet": "#03bdf5",
    "GoldenBet": "#03bdf5",
    "JackBit": "#03bdf5",
    "VeloBet": "#03bdf5",
    "Rolletto": "#03bdf5",
    "DonBet": "#03bdf5",
    "CosmoBet": "#03bdf5",
    "20Bets": "#03bdf5",

    "1xBet": "#045e9b",
    "Bet2Fun": "#045e9b",
    "22Bet": "#045e9b",
    "CoinPlay": "#045e9b",
    "VivatBet": "#045e9b",
    "MelBet": "#045e9b",
    "BetWinner": "#045e9b",
    "888Starz": "#045e9b",
    "BetPari": "#045e9b",
    "HelaBet": "#045e9b",
    "MegaPari": "#045e9b",
    "PariPesa": "#045e9b",
    "SapphireBet": "#045e9b",

    "YoniBet": "#193242",
    "Casinozer": "#193242",
    "Betify": "#193242",
    "BCGame": "#193242",
    "RooBet": "#193242",
    "RollBit": "#193242",
    'LadyLinda': "#193242",
    'BdmBet': "#193242",
    'BetSpino': "#193242",
    'Betiro': "#193242",
    'BetOnRed': "#193242",
    'BitUBet': "#193242",
    'CasinoEstrella': "#193242",
    'CryptoLeo': "#193242",
    'HexaBet2': "#193242",
    'Rollino': "#193242",
    'SuperBoss': "#193242",
    'WsmCasino': "#193242",
    'BlueChip': "#193242",
    'Bons': "#193242",
    'Casino-x': "#193242",
    'FortuneJack': "#193242",
    'WettenLive': "#193242",
    'BetFury': "#193242",
    'RamenBet': "#193242",
    'NineCasino': "#193242",
    'BrunoCasino': "#193242",
    'JoyCasino': "#193242",
    'AnonymBet': "#193242",
    'BetTilt': "#193242",
    'Bitz9': "#193242",
    'BlackMagicCasino': "#193242",
    'BloodMoonCasino': "#193242",
    'ChillBet': "#193242",
    'Chips': "#193242",
    'Crashino': "#193242",
    'Esportiva': "#193242",
    'GoldenReels': "#193242",
    'HiperWin': "#193242",
    'LuckOfSpins': "#193242",
    'LuckyBlock': "#193242",
    'MegaDice': "#193242",
    'SlotsNBets': "#193242",
    'PlayersClubVip': "#193242",
    'RoyalOakCasino': "#193242",
    'SnatchCasino6': "#193242",
    'TivitBet': "#193242",
    "Bet7K": "#193242",
    "Blaze1": "#193242",
    "SlotsSafari": "#193242",
    "TrBet": "#193242",
    "RajaBets": "#193242",
    "CaptainsBet": "#193242",

    "BetOnline": "#c54444",
    "SportsBetting": "#c54444",
    "TigerGaming": "#c54444",

    "BetRebels": "#016301",
    "Sportaza": "#016301",
    "Rabona": "#016301",
    "FezBet": "#016301",
    "Amunra": "#016301",
    "MaxxWin": "#016301",
    "24Bettle": "#016301",
    "4Kasino": "#016301",
    "BBets": "#016301",
    "BankOnBet": "#016301",
    "Betinia": "#016301",
    "BetRiot": "#016301",
    "Betti": "#016301",
    "CampeOnBet": "#016301",
    "Big5Casino": "#016301",
    "Cashed": "#016301",
    "GozaBet": "#016301",
    "Casinoly": "#016301",
    "DivasLuckCasino": "#016301",
    "ExciteWin": "#016301",
    "GreatWin": "#016301",
    "LightCasino": "#016301",
    "LuckyBull": "#016301",
    "Gambeta10": "#016301",
    "CasinoSieger": "#016301",
    "MrPacho": "#016301",
    "Nomini": "#016301",
    "PalmsBet": "#016301",
    "PlayZilla": "#016301",
    "OhMySpins": "#016301",
    "PowBet": "#016301",
    "SlotsHammer": "#016301",
    "SvenPlay": "#016301",
    "VipArabClub": "#016301",
    "Wazamba": "#016301",
    "Winpot": "#016301",
    "Zet": "#016301",
    "RembrandtCasino": "#016301",
    "Pedalada10": "#016301",
    "SpinCashWin": "#016301",
    "EvoBet": "#016301",

    "Bets.io": "#be7b5d",
    "CobraCasino": "#be7b5d",
    "WooCasino": "#be7b5d",
    "QBet": "#be7b5d",

    "CloverSpin": "#775e62",
    "Winz": "#775e62",
    "1Red": "#775e62",
    "BetiBet": "#775e62",
    "N1Bet": "#775e62",
    "Wild": "#775e62",
    "RocketPlay": "#775e62",
    "BelaBet": "#775e62",
    "PlatinCasino": "#775e62",
    "SixDot": "#775e62",
    "ZotaBet": "#775e62",
    "LamaBet": "#775e62",
    "BetBeast": "#775e62",
    "MetaSpins": "#775e62",
    "DailySpins": "#775e62",
    "CoinsGame": "#775e62",

    "UniBet.com": "#739534"
}

function BooksRepartitionChart({ t, bets }) {
    const booksCounter = {};
    Object.keys(stylizedBookName).forEach(book => booksCounter[book] = 0);
    bets.forEach(bet => booksCounter[bet.bookName]++);

    const sortedBooks = Object.keys(booksCounter).sort((a, b) => booksCounter[b] - booksCounter[a]);
    const filteredBooks = sortedBooks.filter(book => booksCounter[book] > 0);

    const data = {
        labels: filteredBooks.map(book => stylizedBookName[book]),
        datasets: [{
            label: t("Paris joués"),
            data: filteredBooks.map(book => booksCounter[book]),
            backgroundColor: Object.values(booksColor)
        }]
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: t("Répartition par bookmaker"),
                color: "#EBE9FC",
                font: {
                    size: 20,
                    family: "font-title"
                }
            },
            legend: {
                display: false,
                labels: {
                    color: "#EBE9FC"
                }
            }
        },
        scales: {
            x: {
                grid: {
                    color: "#4a4a4a"
                },
                ticks: {
                    color: "#EBE9FC"
                }
            },
            y: {
                ticks: {
                    color: "#EBE9FC"
                },
                grid: {
                    color: "grey"
                }
            }
        }
    };

    return <div className="booksChart"><Bar data={data} options={options} /></div>
}

export default BooksRepartitionChart
