import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Legend, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { stylizedSportsName } from "../utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Tooltip, Legend, Title);

const sportsColor = { "football": "#0a9396", "tennis": "#ae2012", "basketball": "#ee9b00", "baseball": "#001219", "rugby": "#94d2bd", "americanFootball": "#ca6702", "hockey": "#9b2226", "handball": "#89BF65", "volleyball": "#2F5597" };

function SportsRepartitionChart({ t, bets }) {
    const sportsCounter = {};
    Object.keys(stylizedSportsName).forEach(sport => sportsCounter[sport] = 0);
    bets.forEach(bet => sportsCounter[bet.sport]++);

    const sortedSports = Object.keys(sportsCounter).sort((a, b) => sportsCounter[b] - sportsCounter[a]);

    const data = {
        labels: sortedSports.map(sport => stylizedSportsName[sport]),
        datasets: [{
            label: t("Paris joués"),
            data: sortedSports.map(sport => sportsCounter[sport]),
            backgroundColor: Object.values(sportsColor)
        }]
    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: t("Répartition par sport"),
                color: "#EBE9FC",
                font: {
                    size: 20,
                    family: "font-title"
                }
            },
            legend: {
                display: false,
                labels: {
                    color: "#EBE9FC"
                }
            }
        },
        scales: {
            x: {
                grid: {
                    color: "#4a4a4a"
                },
                ticks: {
                    color: "#EBE9FC"
                }
            },
            y: {
                ticks: {
                    color: "#EBE9FC"
                },
                grid: {
                    color: "grey"
                }
            }
        }
    };

    return <div className="sportsRepartitionChart"><Bar data={data} options={options} /></div>
}

export default SportsRepartitionChart
