import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import ENTranslations from "../src/i18n/en.json";
import FRTranslations from "../src/i18n/fr.json";

const resources = {
    en: {
        translation: ENTranslations
    },
    fr: {
        translation: FRTranslations
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: { order: ["navigator", "path"] },
    //debug: true,
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

  export default i18n;