import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function FAQ({ t, Trans }) {
    const navigate = useNavigate();

    const handleQuestionClick = (e) => {
        let currentItem = e.target.closest(".item");
        currentItem.classList.toggle("opened");
    }

    const handleValueBettingClick = () => {
        navigate("/valueBetting", { replace: true });
    }

    const handleContactClick = () => {
        navigate("/contact", { replace: true });
    }

    const handleRegisterClick = () => {
        navigate("/register", { replace: true });
    }

    const handlePricingClick = () => {
        navigate("/pricing", { replace: true });
    }

    return (
        <div id="FAQ">
            <h1>{t("Foire aux questions")}</h1>

            <p>{t("Beaucoup de parieurs avant toi se sont posés des questions pertinentes sur BSM... Nous les avons toutes rassemblées dans cette rubrique !")}</p>

            <div id="items">
                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title1")}</div>
                    <div className="content"><Trans i18nKey="FAQ_description1">Rien n'est jamais garanti dans les paris sportifs ! Nous t'assurons simplement d'avoir un avantage statistique sur les événements. Nous y revenons longuement <strong className="link" onClick={handleValueBettingClick}>ici</strong>. Sur le long terme, ce positionnement sera toujours rentable. Seul le volume de paris pris lissera la variance de tes performances.</Trans></div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title2")}</div>
                    <div className="content">{t("FAQ_description2")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title3")}</div>
                    <div className="content">{t("FAQ_description3")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title4")}</div>
                    <div className="content"><Trans i18nKey="FAQ_description4">La liste exhaustive des soft bookmakers pris en charge par le robot est disponible <strong className="link" onClick={handlePricingClick}>ici</strong>. Si tu ne trouves pas celui que tu utilises, n'hésite pas à nous écrire <strong className="link" onClick={handleContactClick}>ici</strong> pour que nous l'intégrions au plus vite !</Trans></div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title5")}</div>
                    <div className="content"><Trans i18nKey="FAQ_description5">Relax… Prend trois jours pour tester gratuitement le produit <strong className="link" onClick={handleRegisterClick}>ici</strong>. Cela ne t'engage à rien et tu seras vite fixé !</Trans></div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title6")}</div>
                    <div className="content">{t("FAQ_description6")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title7")}</div>
                    <div className="content">{t("FAQ_description7")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title8")}</div>
                    <div className="content">{t("FAQ_description8")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title9")}</div>
                    <div className="content"><Trans i18nKey="FAQ_description9">Nous t'expliquons ces notions clés <strong className="link" onClick={handleValueBettingClick}>ici</strong>. Fais-y un tour !</Trans></div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title10")}</div>
                    <div className="content">{t("FAQ_description10")}</div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title11")}</div>
                    <div className="content">
                        <Trans i18nKey="FAQ_description11">
                            Ta bankroll est ton outil de travail. Adapter tes mises est donc crucial pour en prendre soin. Plusieurs manière de procéder te seront proposées :
                            <ul>
                                <li>Flat = mêmes mises quelles que soient les cotes et les values. Simplicité d'utilisation mais forte soumission à variance sur les grosses cotes.</li>
                                <li>Kelly = mises les plus évolutives, fonction des cotes et des values. Rentabilité maximale mais forte résistance mentale requise.</li>
                                <li>Hauteur de cotes = bon compromis entre Flat et Kelly. Dégressivité des mises en fonction du niveau des cotes.</li>
                            </ul>
                        </Trans>
                    </div>
                </div>

                <div className="item" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title12")}</div>
                    <div className="content">{t("FAQ_description12")}</div>
                </div>

                <div className="item" id="majorLeagues" onClick={handleQuestionClick}>
                    <div className="title"><IoIosArrowForward /> {t("FAQ_title13")}</div>
                    <div className="content">
                        <p>
                            <strong>{t("Baseball")} :</strong>
                            <ul>
                                <li>World Cup Baseball</li>
                                <li>MLB</li>
                                <li>Olympics - Men</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Basketball")} :</strong>
                            <ul>
                                <li>NBA, NBA All Star Game</li>
                                <li>FIBA - World Cup</li>
                                <li>Olympic - Basketball</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Football")} :</strong>
                            <ul>
                                <li>Afrique : CAF, Cup of Nations</li>
                                <li>Allemagne - Super Cup, Bundesliga, Cup</li>
                                <li>Angleterre : Championship, EFL Cup, Premier League, FA Cup</li>
                                <li>Danemark - Superliga</li>
                                <li>Espagne - La Liga, Super Cup, Segunda Division</li>
                                <li>FIFA : World Cup, Club World Cup</li>
                                <li>France - Cup, Super Cup, Ligue 2, Ligue 1</li>
                                <li>Italie - Cup, Super Cup, Serie B, Serie A</li>
                                <li>Pays-bas - Super Cup, Eredivisie</li>
                                <li>Portugal - Super Cup, Primeira Liga, Cup</li>
                                <li>Suisse - Cup, Super League</li>
                                <li>UEFA : Super Cup, Champions League, Europa Conference League, EURO, EURO Qualifiers, Champions League Qualifiers</li>
                                <li>USA - Major League Soccer</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Football americain")} :</strong>
                            <ul>
                                <li>NFL</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Hockey")} :</strong>
                            <ul>
                                <li>Olympics - Men</li>
                                <li>NHL</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Rugby")} :</strong>
                            <ul>
                                <li>Six Nations</li>
                                <li>Olympics - Men</li>
                                <li>Rugby World Cup</li>
                            </ul>
                        </p>
                        <p>
                            <strong>{t("Tennis")} :</strong>
                            <ul>
                                <li>ATP/WTA French Open : {t("simples (dont qualificiations)")}, {t("doubles (dont qualifications)")}</li>
                                <li>ATP/WTA Australian Open : {t("simples (dont qualificiations)")}, {t("doubles (dont qualifications)")}</li>
                                <li>ATP/WTA US Open : {t("simples (dont qualificiations)")}, {t("doubles (dont qualifications)")}</li>
                                <li>ATP/WTA Wimbledon : {t("simples (dont qualificiations)")}, {t("doubles (dont qualifications)")}</li>
                                <li>ATP/WTA Masters 1000 : Indian Wells, Miami, Monte-Carlo, Madrid, Rome, Montreal, Toronto, Cincinnati, Shanghai, Paris {t("simples (hors qualifications)")}</li>
                                <li>ATP/WTA 500 : Rotterdam, Rio de Janeiro, Dubai, Acapulco, Barcelona, Halle, London, Hamburg, Washington, Tokyo, Beijing, Guadalajara, Seoul {t("simples (hors qualifications)")}</li>
                                <li>ATP/WTA 250 : Brisbane, Hong Kong, Adelaide, Auckland, Montpellier, Dallas, Marseille, Cordoba, Delray Beach, Buenos Aires, Doha, Los Cabos, Santiago, Houston, Marrakech, Estoril, Bucharest, Munich, Geneva, Lyon, Stuttgart, Hertogenbosch, Mallorca, Eastbourne, Newport, Bastad, Gstaad, Umag, Kitzbuhel, Atlanta, Winston-Salem, Chengdu, Zhuhai, Astana, Antwerp, Stockholm, Vienna, Basel, Gijon, Metz, Pune, Banja Luka, Sofia, Hua-Hin, Hangzhou {t("simples (hors qualifications)")}</li>
                                <li>Olympics</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ
