import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { formatBigNumber } from "../utils";

function Home({ t, isAxiosReady, Trans }) {
    const navigate = useNavigate();

    const handleClickOnFreePlan = () => {
        navigate("/register", { replace: true });
    }

    const handleClickOnValueBetting = () => {
        navigate("/valueBetting", { replace: true });
    }

    const handleClickOnFAQ = () => {
        navigate("/FAQ", { replace: true });
    }

    const handleClickOnTrustpilot = () => {
        window.open("https://fr.trustpilot.com/review/bet-set-match.com", "_blank");
    }

    const handleClickOnX = () => {
        window.open("https://twitter.com/BetSetMatchBSM", "_blank");
    }

    const handleClickOnAnnouncements = () => {
        window.open("https://t.me/+gC8elKvmN0E2ZTk0", "_blank");
    }

    const handleClickOnHotline = () => {
        window.open("https://t.me/+fo89d_LQ9ZMxMzlk", "_blank");
    }

    const [nbBetsPastDay, setNbBetsPastDay] = useState("-");
    const [nbBetsPastWeek, setNbBetsPastWeek] = useState("-");
    const [nbBetsPastMonth, setNbBetsPastMonth] = useState("-");
    const [nbSureBetsPastMonth, setNbSureBetsPastMonth] = useState("-");

    const estimatedROI = 0.035;

    const minBankroll = 1000;
    const maxBankroll = 10000;
    const [bankroll, setBankroll] = useState(5000);

    const minUnit = 0.25;
    const maxUnit = 1.5;
    const [unit, setUnit] = useState(1);
    const getUnitBet = () => bankroll * unit / 100;

    const minAvailability = 10;
    const maxAvailability = 100;
    const [availability, setAvailability] = useState(50);
    const getNbBet = (nb) => nb * 10;

    const getBenefitPerMonth = () => getUnitBet() * getNbBet(availability) * estimatedROI;

    useEffect(() => {
        console.log(isAxiosReady);
        if (isAxiosReady) {
            axios.get("/util/keyFigures").then(response => {
                const keyFigures = response.data;
                setNbBetsPastDay(keyFigures.nbBetsPastDay);
                setNbBetsPastWeek(keyFigures.nbBetsPastWeek);
                setNbBetsPastMonth(keyFigures.nbBetsPastMonth);
                setNbSureBetsPastMonth(keyFigures.nbSureBetsPastMonth);
            });
        }
    }, [isAxiosReady]);

    return (
        <div id="home">
            <section id="strengthsSection">
                <h1 className="mainTitle"><Trans i18nKey="becomeWinningBettor"><strong>Deviens un parieur rentable</strong> sur le long terme</Trans></h1>
                <h2>{t("Robot référence en value betting - Approche OBJECTIVE - accessible à tous")}</h2>

                <div id="strengths">
                    <div id="trophyContainer">
                        <img src="./images/trophy.png" id="trophy" alt="trophy" />
                        <div id="first" className="strength">
                            <img src="./images/1.png" alt="1" />
                            <h3 className="title"><Trans i18nKey="home_easyAndQuick">Prise en main <strong>facile et rapide</strong></Trans></h3>
                            <p className="content">{t("home_easyAndQuick_description")}</p>
                        </div>
                        <div id="second" className="strength">
                            <img src="./images/2.png" alt="2" />
                            <h3 className="title"><Trans i18nKey="home_optimizedReception"><strong>Réception optimisée</strong> des paris</Trans></h3>
                            <p className="content">{t("home_optimizedReception_description")}</p>
                        </div>
                        <div id="third" className="strength">
                            <img src="./images/3.png" alt="3" />
                            <h3 className="title"><Trans i18nKey="home_fullPersonalized">Expérience <strong>100% personnalisée</strong></Trans></h3>
                            <p className="content">{t("home_fullPersonalized_description")}</p>
                        </div>
                        <div id="fourth" className="strength">
                            <img src="./images/4.png" alt="4" />
                            <h3 className="title"><Trans i18nKey="home_independence"><strong>Indépendance</strong> totale</Trans></h3>
                            <p className="content">{t("home_independence_description")}</p>
                        </div>
                        <div id="fifth" className="strength">
                            <img src="./images/5.png" alt="5" />
                            <h3 className="title"><Trans i18nKey="home_manyBookmakers"><strong>Large éventail</strong> de bookmarkers</Trans></h3>
                            <p className="content">{t("home_manyBookmakers_description")}</p>
                        </div>
                        <div id="sixth" className="strength">
                            <img src="./images/6.png" alt="6" />
                            <h3 className="title"><Trans i18nKey="home_analysis"><strong>Analyse précise</strong> de vos paris</Trans></h3>
                            <p className="content">{t("home_analysis_description")}</p>
                        </div>
                        <div id="seventh" className="strength">
                            <img src="./images/7.png" alt="7" />
                            <h3 className="title"><Trans i18nKey="home_backtest">Module de <strong>backtest performant</strong></Trans></h3>
                            <p className="content">{t("home_backtest_description")}</p>
                        </div>
                        <div id="eighth" className="strength">
                            <img src="./images/8.png" alt="8" />
                            <h3 className="title"><Trans i18nKey="home_byBettorsForBettors">Conçu <strong>par des parieurs pour parieurs</strong></Trans></h3>
                            <p className="content">{t("home_byBettorsForBettors_description")}</p>
                        </div>
                    </div>
                </div>

                <div id="generateFirstProfitsContainer">
                    <button className="btn thematized" onClick={handleClickOnFreePlan}>{t("Générer mes premiers profits")}</button>
                    <p>
                        <img src="./images/security.png" alt="no payment required" />
                        {t("Aucun moyen de paiement n'est requis !")}
                    </p>
                </div>
            </section>

            <hr />

            <section id="servicesSection">
                <h1><Trans i18nKey="home_developFullPotential">Développe ton <strong>plein potentiel</strong> de parieur<br /> avec une <strong>offre complète</strong></Trans></h1>

                <div id="services">
                    <div className="service">
                        <div className="nbBetsContainer">
                            <img src="./images/valueBetting.png" alt="value betting" />
                            <p>
                                <span className="nbBets">{formatBigNumber(nbBetsPastMonth)}</span><br />
                                <span className="label">{t("paris le mois dernier")}</span>
                            </p>
                        </div>
                        <div className="serviceDetailsContainer">
                            <div className="titlesContainer">
                                <div className="number">01</div>
                                <div className="titles">
                                    <h2>{t("Value betting")}</h2>
                                    <h3>{t("Plus gros gains")}</h3>
                                </div>
                            </div>
                            <p className="description">{t("Parier sur des cotes mal ajustées, sur-rémunèrant les probabilités réelles.")}</p>
                        </div>
                        <div className="prosConsContainer">
                            <div className="prosContainer">
                                <img src="./images/like.png" alt="pros" />
                                <ul>
                                    <li>{t("Façon de parier la plus rentable")}</li>
                                    <li>{t("Enorme volume de paris proposés")}</li>
                                </ul>
                            </div>
                            <div className="consContainer">
                                <img src="./images/dislike.png" alt="cons" />
                                <ul>
                                    <li>{t("Forte variance des gains")}</li>
                                    <li>{t("Bankroll élevée requise")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="service">
                        <div className="nbBetsContainer">
                            <img src="./images/sureBetting.png" alt="sure betting" />
                            <p>
                                <span className="nbBets">{formatBigNumber(nbSureBetsPastMonth)}</span><br />
                                <span className="label">{t("paris le mois dernier")}</span>
                            </p>
                        </div>
                        <div className="serviceDetailsContainer">
                            <div className="titlesContainer">
                                <div className="number">02</div>
                                <div className="titles">
                                    <h2>{t("Sure betting")}</h2>
                                    <h3>{t("ASSURANCE DE PROFITS")}</h3>
                                </div>
                            </div>
                            <p className="description">{t("Jouer toutes les issues possibles d'un évènement, avec une garantie de sortir gagnant.")}</p>
                        </div>
                        <div className="prosConsContainer">
                            <div className="prosContainer">
                                <img src="./images/like.png" alt="pros" />
                                <ul>
                                    <li>{t("Gains certains à chaque match")}</li>
                                    <li>{t("Tranquilité d'esprit")}</li>
                                </ul>
                            </div>
                            <div className="consContainer">
                                <img src="./images/dislike.png" alt="cons" />
                                <ul>
                                    <li>{t("Faible volume de paris proposés")}</li>
                                    <li>{t("Petits gains générés")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="service">
                        <div className="nbBetsContainer">
                            <img src="./images/liveBetting.png" alt="live betting" />
                            <p>
                                <span className="nbBets">-</span><br />
                                <span className="label">{t("paris le mois dernier")}</span>
                            </p>
                        </div>
                        <div className="serviceDetailsContainer">
                            <div className="titlesContainer">
                                <div className="number">03</div>
                                <div className="titles">
                                    <h2>{t("Live betting")}</h2>
                                    <h3>{t("Rentabilité immédiate")}</h3>
                                </div>
                            </div>
                            <p className="description">{t("Profiter d'erreurs de cotes sur des matchs en cours, quelle que soit l'heure.")}</p>
                        </div>
                        <div className="prosConsContainer">
                            <div className="prosContainer">
                                <img src="./images/like.png" alt="pros" />
                                <ul>
                                    <li>{t("Résultats quasi immédiats")}</li>
                                    <li>{t("Plus hautes limites de mises")}</li>
                                </ul>
                            </div>
                            <div className="consContainer">
                                <img src="./images/dislike.png" alt="cons" />
                                <ul>
                                    <li>{t("Grande disponibilité requise")}</li>
                                    <li>{t("Variations très rapides des cotes")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr />

            <section id="clientsOpinionsSection">
                <h1><Trans i18nKey="home_joinThousandsBettors">Rejoins des milliers de <strong>parieurs gagnants</strong></Trans></h1>

                <p>{t("Les avis de nos parieurs sont le faire-valoir de la qualité de notre robot et des services associés.")}</p>

                <div className="trustContainer">
                    <div className="trustPilotContainer">
                        <div className="label">{t("Note de nos clients sur")} <br />Trustpilot</div>
                        <div className="noteContainer">
                            <img src="./images/star.png" alt="trustpilot" />
                            <br />
                            <span className="currentNote">4,2</span>
                            <span className="maxNote">/ 5</span>
                        </div>
                    </div>
                    <div className="socialNetworksContainer">
                        <div className="socialNetwork" onClick={handleClickOnTrustpilot}>
                            <img src="./images/trustpilot_logo.png" alt="TrustPilot" />
                            TrustPilot
                        </div>
                        <div className="socialNetwork" onClick={handleClickOnX}>
                            <img src="./images/x.png" alt="BetSet&Match" />
                            BetSet&Match
                        </div>
                        <div className="socialNetwork" onClick={handleClickOnAnnouncements}>
                            <img src="./images/announcements.png" alt="Annonces officielles" />
                            {t("Annonces")}
                        </div>
                        <div className="socialNetwork" onClick={handleClickOnHotline}>
                            <img src="./images/hotline.png" alt="Hotline FR" />
                            {t("Hotline FR")}
                        </div>
                    </div>
                </div>

                <hr />

                <div className="bestCommentsContainer">
                    <div className="bestComment">
                        <div className="header">
                            <div className="usernameDate">
                                <span className="username">Arthur</span>
                                <span className="date">{t("Le 27/03/2024")}</span>
                            </div>
                            <div className="notation">
                                <img src="./images/star.png" alt="star" />
                                <span className="currentNote">5</span>
                                <span className="maxNote">/ 5</span>
                            </div>
                        </div>
                        <div className="contentContainer">
                            <h6 className="title">{t("Service à la pointe")}</h6>
                            <p className="content">{t("home_arthurOpinion")}</p>
                        </div>
                    </div>
                    <div className="bestComment">
                        <div className="header">
                            <div className="usernameDate">
                                <span className="username">Félix</span>
                                <span className="date">{t("Le 28/03/2024")}</span>
                            </div>
                            <div className="notation">
                                <img src="./images/star.png" alt="star" />
                                <span className="currentNote">5</span>
                                <span className="maxNote">/ 5</span>
                            </div>
                        </div>
                        <div className="contentContainer">
                            <h6 className="title">{t("Le meilleur outil de détection de values sur le marché")}</h6>
                            <p className="content">
                                <Trans i18nKey="home_felixOpinion">
                                    Tout simplement le meilleur outil de détections de values à l'heure actuelle.<br />
                                    <br />Points forts :<br />
                                    - Une équipe à l'écoute des clients qui met continuellement à jour l'outil afin de l'améliorer<br />
                                    - Un volume de paris très élevé tous les mois qui permet de lisser plus rapidement la variance liée à ce type d'activité.<br />
                                    - Les liens URL disponibles pour accéder au pari plus rapidement et donc gagner en réactivité.<br />
                                    - Une sélection de bookmaker très fournie qui dépasse largement l'éventail proposé par les outils concurrentiels. Il en va de même pour les marchés proposés sur tous les sports.<br />
                                    - Il n'est pas nécessaire d'être "bloqué" devant le PC pour prendre les paris. Un téléphone suffit et permet une grande liberté de mobilité.<br />

                                    <br />Point faible :<br />
                                    - Le prix mensuel. Ce n'est pas un point si faible que ça lorsque votre capital alloué aux paris sportifs devient plus conséquent. De plus le volume permet à terme de rentabiliser rapidement le coût de l'abonnement, et ce même avec une unité de mise relativement basse.
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className="bestComment">
                        <div className="header">
                            <div className="usernameDate">
                                <span className="username">Quentin</span>
                                <span className="date">{t("Le 30/03/2024")}</span>
                            </div>
                            <div className="notation">
                                <img src="./images/star.png" alt="star" />
                                <span className="currentNote">5</span>
                                <span className="maxNote">/ 5</span>
                            </div>
                        </div>
                        <div className="contentContainer">
                            <h6 className="title">{t("Bet-Set&Match est un outil à avoir")}</h6>
                            <p className="content">{t("home_quentinOpinion")}</p>
                        </div>
                    </div>
                </div>
            </section>

            <hr />

            <section id="benefitsSimulatorSection">
                <h1><Trans i18nKey="home_simulateEarnings">Simule tes <strong>gains potentiels</strong>, fonction de <br />ta <strong>bankroll</strong>, tes <strong>MISES</strong> et tes <strong>disponibilités</strong> !</Trans></h1>

                <div id="keyParametersContainer">
                    <div className="keyParameter">
                        <img src="./images/bankroll.png" alt="bankroll" />
                        <div className="parameter">
                            <div className="title">{t("Bankroll")} : <strong>{formatBigNumber(bankroll).replace(".", t("decimalSeparator"))} €</strong></div>
                            <div className="sliderContainer">
                                <div className="start">{t("Petite")}<br />({formatBigNumber(minBankroll)} €)</div>
                                <input type="range" min={minBankroll} max={maxBankroll} step="100" value={bankroll} onChange={event => setBankroll(event.target.value)} />
                                <div className="end">{t("Grosse")}<br />({formatBigNumber(maxBankroll)} €)</div>
                            </div>
                        </div>
                    </div>

                    <div className="keyParameter">
                        <img src="./images/unit.png" alt="unit" />
                        <div className="parameter">
                            <div className="title">{t("Mise moyenne")} : <strong>{getUnitBet().toFixed(2).replace(".", t("decimalSeparator"))} € ({unit.toString().replace(".", t("decimalSeparator"))} %)</strong></div>
                            <div className="sliderContainer">
                                <div className="start">{t("Prudente")}<br />({minUnit.toString().replace(".", t("decimalSeparator"))} %)</div>
                                <input type="range" min={minUnit} max={maxUnit} step="0.05" value={unit} onChange={event => setUnit(event.target.value)} />
                                <div className="end">{t("Agressive")}<br />({maxUnit.toString().replace(".", t("decimalSeparator"))} %)</div>
                            </div>
                        </div>
                    </div>

                    <div className="keyParameter">
                        <img src="./images/availability.png" alt="availability" />
                        <div className="parameter">
                            <div className="title">{t("Disponibilités")} : <strong>{getNbBet(availability)} {t("paris par mois")}</strong></div>
                            <div className="sliderContainer">
                                <div className="start">{t("Faible")}<br />({getNbBet(minAvailability)} {t("paris")})</div>
                                <input type="range" min={minAvailability} max={maxAvailability} step="5" value={availability} onChange={event => setAvailability(event.target.value)} />
                                <div className="end">{t("Forte")}<br />({formatBigNumber(getNbBet(maxAvailability))} {t("paris")})</div>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="benefitsResults">{t("Profits estimés sur le long terme")} : <strong>{formatBigNumber(parseInt(getBenefitPerMonth()))} €</strong> {t("par mois")}  /  <strong>{formatBigNumber(parseInt(getBenefitPerMonth() * 12))} €</strong> {t("par an")}</p>
                <p className="benefitsAlert">{t("home_alertROI", { ROI: (estimatedROI * 100).toFixed(2).replace(".", t("decimalSeparator")) })}</p>
            </section>

            <hr />

            <section id="nextStepsSection">
                <h1>{t("tu es arrivé jusqu'ici...")}</h1>

                <div className="itemsContainer">
                    <div className="item">
                        <p>... {t("et ne saisis toujours pas bien les concepts du value betting")}</p>
                        <button onClick={handleClickOnValueBetting} className="btn thematized">{t("Tout comprendre sur les paris sportifs")}</button>
                    </div>

                    <div className="item">
                        <p>... {t("et te poses encore beaucoup de questions")}</p>
                        <button onClick={handleClickOnFAQ} className="btn thematized">{t("Des réponses dans la FAQ")}</button>
                    </div>

                    <div className="item">
                        <p>... {t("et souhaites te lancer sans plus attendre")}</p>
                        <button onClick={handleClickOnFreePlan} className="btn thematized">{t("Démarrer une période d'essai gratuite")}</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home
