import React, { useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

function DHMSFormField({ t, id, value, label, className = "", disabled, setDisabled, tooltip, conditionable = false, onChange }) {
    const [days, setDays] = useState(Math.floor(value / 86400));
    const [hours, setHours] = useState(Math.floor((value - days * 86400) / 3600));
    const [minutes, setMinutes] = useState(Math.floor((value - days * 86400 - hours * 3600) / 60));
    const [seconds, setSeconds] = useState(value - days * 86400 - hours * 3600 - minutes * 60);
    const [isDisabled, setIsDisabled] = useState(disabled);

    useEffect(() => {
        onChange(days * 86400 + hours * 3600 + minutes * 60 + seconds);
    }, [days, hours, minutes, seconds]);

    const handleUpdateDisable = (newValue) => {
        setIsDisabled(newValue);
        setDisabled(!newValue);
    }

    return (
        <div className={"formField dhms " + className}>
            <label>
                {label}
                {tooltip ? <BsFillInfoCircleFill className="tooltipTrigger" id={tooltip} /> : null}
                {" "}:
            </label>
            {
                conditionable ?
                    <input type="checkbox" id={id + "-isActive"} className="conditionableField" checked={!isDisabled} onChange={e => handleUpdateDisable(!isDisabled)} />
                    : null
            }
            <input type="number" disabled={conditionable && isDisabled} min="0" name={id + "-day"} id={id + "-day"} value={days} placeholder={t("J")} onChange={(e) => setDays(parseInt(e.target.value))} /> {t("J")}
            <input type="number" disabled={conditionable && isDisabled} min="0" max="23" name={id + "-hour"} id={id + "-hour"} value={hours} placeholder={t("H")} onChange={(e) => setHours(parseInt(e.target.value))} /> {t("H")}
            <input type="number" disabled={conditionable && isDisabled} min="0" max="59" name={id + "-minute"} id={id + "-minute"} value={minutes} placeholder={t("M")} onChange={(e) => setMinutes(parseInt(e.target.value))} /> {t("M")}
            <input type="number" disabled={conditionable && isDisabled} min="0" max="59" name={id + "-second"} id={id + "-second"} value={seconds} placeholder={t("S")} onChange={(e) => setSeconds(parseInt(e.target.value))} /> {t("S")}
        </div >
    )
}

export default DHMSFormField
