import React, { useState } from 'react'
import FormField from '../components/FormField'
import axios from 'axios'
import { useNavigate, Link } from "react-router-dom";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Login({ t, login, setIsLoading, setLoadingText }) {
    const navigate = useNavigate();

    const handleClickOnFreePlan = () => {
        navigate("/register", { replace: true });
    }

    // LOGIN
    const [identifier, setIdentifier] = useState(/*"test"*/);
    const [password, setPassword] = useState(/*"test"*/);
    const [loginErrors, setLoginErrors] = useState();
    const handleLogin = (e) => {
        e.preventDefault();

        let errors = []
        if (!identifier || (identifier && identifier.length === 0))
            errors.push(t("Veuillez saisir un identifiant"));
        if (!password || (password && password.length === 0))
            errors.push(t("Veuillez saisir un mot de passe"));

        if (errors.length > 0) {
            setLoginErrors(errors);
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Vérification de vos informations..."));

            axios.post("user/signIn", { identifier, password }).then((result) => {
                login(result.data.token, result.data.user);
                setLoginErrors([]);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setLoginErrors(err.response.data.errors);
                setIsLoading(false);
            });
        }
    }

    return (
        <div id="login">
            <p className="noAccount">{t("Tu n'as pas encore de compte ?")} <strong onClick={handleClickOnFreePlan}>{t("Commence ta période d'essai gratuite !")}</strong></p>

            <h2><hr /><span>{t("Connexion")}</span></h2>

            <form onSubmit={handleLogin}>
                <div className="formContent">
                    <div className="rightSide">
                        <FormField className="thematized" type="text" id="identifier" value={identifier} label={t("Pseudo ou email")} placeholder={t("Ton pseudo ou email")} onChange={setIdentifier} />
                        <FormField className="thematized" type="password" id="password" value={password} label={t("Mot de passe")} placeholder={t("Ton mot de passe")} onChange={setPassword} />

                        {(loginErrors && loginErrors.length > 0) ? <ul className="formError">{loginErrors.map((error, index) => <li key={index}>{error}</li>)}</ul> : null}

                        <button className="btn thematized" type="submit">{t("Se connecter")}</button>
                    </div>
                    <div className="leftPart">
                        <img src="./images/login_player.png" alt="login illustration" />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login
