import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AiFillEuroCircle } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import CLVAndEVAndBKChart from './../components/CLVAndEVAndBKChart';
import SportsRepartitionChart from './../components/SportsRepartitionChart';
import BooksRepartitionChart from './../components/BooksRepartitionChart';
import FormField from '../components/FormField';
import MultipleFormField from '../components/MultipleFormField';
import DHMSFormField from '../components/DHMSFormField';
import { stylizedBookName, stylizedSport, allSports, allFrenchBooks, allWorldBooks, allMarkets, getPrintableBet, isBetWon, formatBigNumber } from "../utils";

function Dashboard({ t, isAxiosReady, setIsLoading, setLoadingText, user }) {
    const [initialBets, setInitialBets] = useState([]);
    const [bets, setBets] = useState([]);
    const [todaysBets, setTodaysBets] = useState([]);

    const today = new Date();
    const startOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const [startPeriod, setStartPeriod] = useState(getDateForInput(startOfCurrentMonth));
    const [endPeriod, setEndPeriod] = useState(getDateForInput(endOfCurrentMonth));

    const [seeMoreActiveFilters, setSeeMoreFiltersActive] = useState(false);
    const [V1Filter, setV1Filter] = useState(true);
    const [V2Filter, setV2Filter] = useState(true);
    const [sportsFilter, setSportsFilter] = useState([]);
    const [booksFilter, setBooksFilter] = useState([]);
    const [marketsFilter, setMarketsFilter] = useState([]);
    const [minOddFilter, setMinOddFilter] = useState(1);
    const [maxOddFilter, setMaxOddFilter] = useState(50);
    const [minTRJFilter, setMinTRJFilter] = useState(0);
    const [maxTRJFilter, setMaxTRJFilter] = useState(200);
    const [minEVFilter, setMinEVFilter] = useState(-50);
    const [maxEVFilter, setMaxEVFilter] = useState(100);
    const [minLiquidityFilter, setMinLiquidityFilter] = useState(1);
    const [maxLiquidityFilter, setMaxLiquidityFilter] = useState(1000000);
    const [minTimeSinceOpeningFilter, setMinTimeSinceOpeningFilter] = useState(0);
    const [maxTimeSinceOpeningFilter, setMaxTimeSinceOpeningFilter] = useState(100 * 86400);
    const [minTimeUntilClosingFilter, setMinTimeUntilClosingFilter] = useState(0);
    const [maxTimeUntilClosingFilter, setMaxTimeUntilClosingFilter] = useState(100 * 86400);

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const betsTaken = bets.filter(bet => bet.hasSucceedToBet);
    const betsTakenWithCLV = betsTaken.filter(bet => "EVAtClosure" in bet && bet.EVAtClosure != null);
    const finishedBetsTaken = betsTakenWithCLV.filter(bet => bet.winner != null);
    const inProgressBetsTaken = betsTaken.filter(bet => bet.winner == null && (bet.results !== "surrender" && bet.winner == null));

    const moneyInProgress = inProgressBetsTaken.length > 0 ? inProgressBetsTaken.reduce((sum, bet) => sum + parseFloat(bet.bet), 0) : 0;
    let wonBets = 0;
    const benefits = finishedBetsTaken.length > 0 ? finishedBetsTaken.reduce((sum, bet) => {
        const { hasWon, isRefunded, isNotFound, benefit } = isBetWon(bet);
        if (isNotFound || isRefunded) {
            return sum;
        } else {
            if (hasWon) {
                wonBets++;
            }

            return sum + benefit;
        }
    }, 0) : 0;

    const totalBetPlayed = betsTaken.length > 0 ? parseInt(betsTaken.reduce((sum, bet) => sum + parseFloat(bet.bet), 0)) : 0;
    let mediumCLV = 0, mediumCLVWeightedByLiquidity = 0, mediumOdd = 0, mediumBet = 0, betsSum = 0, liquiditiesSum = 0;
    if (finishedBetsTaken.length > 0) {
        finishedBetsTaken.forEach(bet => {
            betsSum += parseFloat(bet.bet);
            mediumCLV += parseFloat(bet.EVAtClosure) * parseFloat(bet.bet);
            mediumOdd += parseFloat(bet.odd.split(";")[0]);
            mediumBet += parseFloat(bet.bet);

            if (bet.liquidities && bet.liquidities.hasOwnProperty(bet.marketName)) {
                liquiditiesSum += parseInt(bet.liquidities[bet.marketName]);
                mediumCLVWeightedByLiquidity += parseFloat(bet.EVAtClosure) * parseInt(bet.liquidities[bet.marketName]);
            }
        })
        mediumCLV /= betsSum;
        mediumOdd /= finishedBetsTaken.length;
        mediumBet /= finishedBetsTaken.length;
        mediumCLVWeightedByLiquidity /= liquiditiesSum;
    }

    useEffect(() => {
        setIsLoading(true);
        setLoadingText(t("Chargement de vos paris..."));
    }, []);

    useEffect(() => {
        if (isAxiosReady) {
            const start = new Date(startPeriod);
            start.setHours(0, 0, 0, 0);
            const end = new Date(endPeriod);
            end.setHours(23, 59, 59, 0);

            axios.get(`/bet?filters=startDate:${start.getTime()},endDate:${end.getTime()}&dashboard=true`).then(response => {
                setInitialBets(response.data);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Chargement de vos paris..."));
        }
    }, [isAxiosReady, startPeriod, endPeriod]);

    useEffect(() => {
        if (isAxiosReady) {
            const startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date();
            endOfDay.setHours(23, 59, 59, 0);

            axios.get(`/bet?filters=startDate:${startOfDay.getTime()},endDate:${endOfDay.getTime()},hasSucceedToBet:1`).then(response => {
                const enrichedBets = response.data.map(bet => {
                    bet.homeTeamName = bet.homeTeamName.split(";")[0];
                    bet.awayTeamName = bet.awayTeamName.split(";")[0];
                    bet.odd = bet.odd.split(";")[0];

                    const { hasWon, isRefunded, isNotFound, benefit } = isBetWon(bet);
                    bet.hasWon = hasWon;
                    bet.isRefunded = isRefunded;
                    bet.isNotFound = isNotFound;
                    bet.benefit = benefit;

                    return bet;
                });
                setTodaysBets(enrichedBets);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
    }, [isAxiosReady]);

    useEffect(() => {
        let filteredBets = [...initialBets];

        // Version filter
        filteredBets = filteredBets.filter(bet => (!bet.isV2 && V1Filter) || (bet.isV2 && V2Filter));

        // Odds filter
        filteredBets = filteredBets.filter(bet => parseFloat(bet.odd) >= parseFloat(minOddFilter) && parseFloat(bet.odd) <= parseFloat(maxOddFilter));

        // Sports filter
        if (sportsFilter.length > 0) {
            filteredBets = filteredBets.filter(bet => sportsFilter.includes(bet.sport));
        }

        // Books filter
        if (booksFilter.length > 0) {
            filteredBets = filteredBets.filter(bet => booksFilter.includes(bet.bookName));
        }

        // Markets filter
        if (marketsFilter.length > 0) {
            filteredBets = filteredBets.filter(bet => {
                return marketsFilter.some(completeMarketName => {
                    const [sport, marketName] = completeMarketName.split("_");
                    return marketName === bet.marketName && sport === bet.sport;
                });
            });
        }

        // Liquidity VS Pinnacle filter
        filteredBets = filteredBets.filter(bet => {
            const pinnacleReference = bet.pinnacleReference.split(";");
            const liquidity = pinnacleReference[pinnacleReference.length - 1];

            return parseInt(liquidity) >= parseInt(minLiquidityFilter) && parseInt(liquidity) <= parseInt(maxLiquidityFilter);
        });

        // TRJ / EV filters
        filteredBets = filteredBets.filter(bet => {
            const TRJ = parseFloat(bet.TRJ) * 100;
            const EV = parseFloat(bet.EV) * 100;

            return TRJ >= parseFloat(minTRJFilter) && TRJ <= parseFloat(maxTRJFilter)
                && EV >= parseFloat(minEVFilter) && EV <= parseFloat(maxEVFilter);
        });

        // Filter on opening / closing
        filteredBets = filteredBets.filter(bet => {
            const sharedTime = new Date(bet.sharedDate).getTime() / 1000;

            let isOpeningOk = true;
            if (bet.openingDate) {
                const openingTime = new Date(bet.openingDate).getTime() / 1000;
                const timeSinceOpening = sharedTime - openingTime;

                isOpeningOk = timeSinceOpening >= minTimeSinceOpeningFilter && timeSinceOpening <= maxTimeSinceOpeningFilter;
            }

            const matchTime = new Date(bet.pinnacleClosureDate).getTime() / 1000;
            const timeUntilClosing = matchTime - sharedTime;
            const isClosingOk = timeUntilClosing >= minTimeUntilClosingFilter && timeUntilClosing <= maxTimeUntilClosingFilter;

            return timeUntilClosing < 0 || (isOpeningOk && isClosingOk);
        });

        setBets(filteredBets);
    }, [initialBets, V1Filter, V2Filter, sportsFilter, booksFilter, marketsFilter, minOddFilter, maxOddFilter, minTRJFilter, maxTRJFilter, minEVFilter, maxEVFilter, minLiquidityFilter, maxLiquidityFilter, minTimeSinceOpeningFilter, maxTimeSinceOpeningFilter, minTimeUntilClosingFilter, maxTimeUntilClosingFilter]);

    return (
        <div id="dashboard">
            <h2>
                {t("Depuis le")} <input type="date" value={startPeriod} onChange={e => setStartPeriod(e.target.value)} />
                {" "}{t("jusqu'au")} <input type="date" value={endPeriod} onChange={e => setEndPeriod(e.target.value)} />

                <button className="btn thematized" onClick={() => setSeeMoreFiltersActive(!seeMoreActiveFilters)}>
                    {t("Voir X de filtres", { "moreOrLess": seeMoreActiveFilters ? t("moins") : t("plus") })}
                </button>
            </h2>

            {seeMoreActiveFilters && (
                <fieldset className="filters">
                    <legend>{t("Filtres")}</legend>

                    <div className="fullWidth filter versionFilterContainer">
                        <FormField t={t} type="checkbox" id="V1Filter" label={t("Montrer uniquement les paris de la")} afterLabel={t("V1")} value={V1Filter} onChange={e => setV1Filter(!V1Filter)} checked={V1Filter} />
                        <FormField t={t} type="checkbox" id="V2Filter" afterLabel={t("V2")} value={V2Filter} onChange={e => setV2Filter(!V2Filter)} checked={V2Filter} />
                    </div>

                    <FormField t={t} id="booksFilter" type="select" options={[...allFrenchBooks, ...Object.values(allWorldBooks).flat()]} multiple={true} label={t("Books")} value={booksFilter} onChange={setBooksFilter} />
                    <FormField t={t} id="sportsFilter" type="select" options={allSports} multiple={true} label={t("Sports")} value={sportsFilter} onChange={setSportsFilter} />
                    <FormField t={t} id="marketsFilter" type="select" options={allMarkets} multiple={true} label={t("Marchés")} value={marketsFilter} onChange={setMarketsFilter} />

                    <MultipleFormField t={t} id={["minOdd", "maxOdd"]} type={["number", "number"]} label={[t("Cote de"), t("to")]} step={[0.01, 0.01]} value={[minOddFilter, maxOddFilter]} onChange={[setMinOddFilter, setMaxOddFilter]} />
                    <MultipleFormField t={t} id={["minTRJ", "maxTRJ"]} type={["number", "number"]} label={[t("TRJ de"), t("to")]} step={[0.01, 0.01]} value={[minTRJFilter, maxTRJFilter]} onChange={[setMinTRJFilter, setMaxTRJFilter]} />
                    <MultipleFormField t={t} id={["minEV", "maxEV"]} type={["number", "number"]} label={[t("EV de"), t("to")]} step={[0.01, 0.01]} value={[minEVFilter, maxEVFilter]} onChange={[setMinEVFilter, setMaxEVFilter]} />
                    <MultipleFormField t={t} id={["minLiquidity", "maxLiquidity"]} type={["number", "number"]} label={[t("Liquidité de"), t("to")]} min={[0, 0]} step={[5, 5]} value={[minLiquidityFilter, maxLiquidityFilter]} onChange={[setMinLiquidityFilter, setMaxLiquidityFilter]} />

                    <div className="fullWidth">
                        <DHMSFormField t={t} id="minTimeSinceOpening" label={t("Temps depuis l'opening : de ")} value={minTimeSinceOpeningFilter} onChange={setMinTimeSinceOpeningFilter} />
                        <DHMSFormField t={t} id="maxTimeSinceOpening" label={t("to")} value={maxTimeSinceOpeningFilter} onChange={setMaxTimeSinceOpeningFilter} />
                    </div>
                    <div className="fullWidth">
                        <DHMSFormField t={t} id="minTimeUntilClosing" label={t("Temps jusqu'au closing : de ")} value={minTimeUntilClosingFilter} onChange={setMinTimeUntilClosingFilter} />
                        <DHMSFormField t={t} id="maxTimeUntilClosing" label={t("to")} value={maxTimeUntilClosingFilter} onChange={setMaxTimeUntilClosingFilter} />
                    </div>
                </fieldset>
            )}

            <div className="keyFigures">
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {betsTaken.length}
                            <small>({bets.length > 0 ? ((betsTaken.length / bets.length) * 100).toFixed(0).replace(".", ",") : "0"}% - {formatBigNumber(parseInt(mediumBet)).replace(".", ",")}{t("€")})</small>
                        </div>
                        <div className="label">
                            {t("paris pris")}
                            <small>({t("% prise - mise moy.")})</small>
                        </div>
                    </div>
                </div>
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {inProgressBetsTaken.length}
                            <small>({formatBigNumber(parseInt(moneyInProgress))}{t("€")})</small>
                        </div>
                        <div className="label">
                            {t("paris en cours")}
                            <small>({t("total engagé")})</small>
                        </div>
                    </div>
                </div>
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {(mediumCLV * 100).toFixed(2).replace(".", ",")}%
                            <small>({(mediumCLVWeightedByLiquidity * 100).toFixed(2).replace(".", ",")}%)</small>
                        </div>
                        <div className="label">
                            {t("CLV /mises")}
                            <small>({t("CLV /liquidités")})</small>
                        </div>
                    </div>
                </div>
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {formatBigNumber(Math.round(benefits))}{t("€")}
                            <small>({formatBigNumber(totalBetPlayed).replace(".", ",")}{t("€")})</small>
                        </div>
                        <div className="label">
                            {t("bénéfices")}
                            <small>({t("total misé")})</small>
                        </div>
                    </div>
                </div>
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {totalBetPlayed > 0 ? (benefits / totalBetPlayed * 100).toFixed(2).replace(".", ",") : "0.00"}%
                            <small>({(benefits / user.capital * 100).toFixed(2).replace(".", ",")}%)</small>
                        </div>
                        <div className="label">
                            {t("ROI")}
                            <small>({t("ROC")})</small>
                        </div>
                    </div>
                </div>
                <div className="keyFigure">
                    <div className="container">
                        <div className="value">
                            {mediumOdd.toFixed(2).replace(".", ",")}
                            <small>({finishedBetsTaken.length > 0 ? parseInt(wonBets / finishedBetsTaken.length * 100).toString().replace(".", ",") : "0"}%)</small>
                        </div>
                        <div className="label">
                            {t("Cote moyenne")}
                            <small>({t("taux de réussite")})</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottomPageContainer">
                <div className="chartsContainer">
                    <div className="graphsContainer">
                        <CLVAndEVAndBKChart t={t} bets={finishedBetsTaken} />
                    </div>
                    <div className="pieChartsContainer">
                        <BooksRepartitionChart t={t} bets={betsTaken} />
                        <SportsRepartitionChart t={t} bets={finishedBetsTaken} />
                    </div>
                </div>

                <div className="datatableContainer">
                    <h2>{t("Paris pris du jour")}</h2>
                    <TodaysBetsTable t={t} bets={todaysBets} />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;

const TodaysBetsTable = ({ t, bets }) => {
    const columns = [
        {
            name: t("Sport"),
            selector: row => {
                if (row.isV2) {
                    return <span className="newVersion">{stylizedSport[row.sport]}</span>;
                } else {
                    return stylizedSport[row.sport];
                }
            }
        },
        { name: t("Match"), selector: row => <>{row.homeTeamName.split(";")[0]}<br />{row.awayTeamName.split(";")[0]}</> },
        {
            name: t("Pari"),
            selector: row => {
                if (row.isNotFound) {
                    return <strong>{getPrintableBet(row.sport, row.marketName, row.side, row.homeTeamName, row.awayTeamName)}</strong>
                } else if (row.isRefunded) {
                    return <strong className="surrender">{getPrintableBet(row.sport, row.marketName, row.side, row.homeTeamName, row.awayTeamName)}</strong>
                } else if (row.hasWon) {
                    return <strong className="won">{getPrintableBet(row.sport, row.marketName, row.side, row.homeTeamName, row.awayTeamName)}</strong>
                } else {
                    return <strong className="lost">{getPrintableBet(row.sport, row.marketName, row.side, row.homeTeamName, row.awayTeamName)}</strong>
                }
            }
        },
        { name: t("Heure"), selector: row => getPrintableTime(row.pinnacleClosureDate), sortable: true, sortFunction: sortByMatchDate },
        { name: t("Bookmaker"), selector: row => row.bookName.split(";").map(bookName => stylizedBookName[bookName]).join(", ") },
        {
            name: t("Cote"),
            selector: row => {
                if (row.EVAtClosure && row.liquidities && row.liquidities.hasOwnProperty(row.marketName)) {
                    return (
                        <div className="oddContainer">
                            <span className="odd">{parseFloat(row.odd).toFixed(2).replace(".", ",")}</span>
                            <small className={row.EVAtClosure > 0 ? "CLV positive" : "CLV negative"}>({row.EVAtClosure > 0 ? "+" : ""}{(row.EVAtClosure * 100).toFixed(2).replace(".", ",")}%)</small>
                            <small>({formatBigNumber(row.liquidities[row.marketName])}{t("€")})</small>
                        </div>
                    );
                } else if (row.EVAtClosure) {
                    return (
                        <div className="oddContainer">
                            <span className="odd">{parseFloat(row.odd).toFixed(2).replace(".", ",")}</span>
                            <small className={row.EVAtClosure > 0 ? "CLV positive" : "CLV negative"}>({row.EVAtClosure > 0 ? "+" : ""}{(row.EVAtClosure * 100).toFixed(2).replace(".", ",")}%)</small>
                        </div>
                    );
                } else {
                    return (
                        <div className="oddContainer">
                            <span className="odd">{parseFloat(row.odd).toFixed(2).replace(".", ",")}</span>
                        </div>
                    );
                }
            }
        },
        {
            name: t("Mise"), selector: row => {
                if (row.isNotFound) {
                    return (
                        <div className="betContainer">
                            <span className="iconAndText">
                                <AiFillEuroCircle />
                                <span>{parseFloat(row.bet).toFixed(2).replace(".", ",")}{t("€")}</span>
                            </span>
                        </div>
                    )
                } else if (row.isRefunded) {
                    return (
                        <div className="betContainer">
                            <span className="iconAndText">
                                <AiFillEuroCircle />
                                <span>{parseFloat(row.bet).toFixed(2).replace(".", ",")}{t("€")}</span>
                            </span>
                            <small className="matchResult surrender">(0{t("€")})</small>
                        </div>
                    )
                } else if (row.hasWon) {
                    return (
                        <div className="betContainer">
                            <span className="iconAndText">
                                <AiFillEuroCircle />
                                <span>{parseFloat(row.bet).toFixed(2).replace(".", ",")}{t("€")}</span>
                            </span>
                            <small className="matchResult won">(+{(row.benefit).toFixed(2).replace(".", ",")}{t("€")})</small>
                        </div>
                    )
                } else {
                    return (
                        <div className="betContainer">
                            <span className="iconAndText">
                                <AiFillEuroCircle />
                                <span>{parseFloat(row.bet).toFixed(2).replace(".", ",")}{t("€")}</span>
                            </span>
                            <small className="matchResult lost">({parseFloat(row.benefit).toFixed(2).replace(".", ",")}{t("€")})</small>
                        </div>
                    )
                }
            }
        }
    ];

    return <DataTable columns={columns} data={bets} defaultSortFieldId="4" defaultSortAsc={true} fixedHeaderScrollHeight='1250px' fixedHeader />
}

const getPrintableTime = (dateIso) => {
    let date = new Date(dateIso);

    let minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    let hours = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();

    return `${hours}:${minutes}`;
}

const getDateForInput = (date) => {
    date = new Date(date);

    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1
    month = month > 9 ? month : "0" + month;
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

const sortByMatchDate = (rowA, rowB) => {
    const a = new Date(rowA.pinnacleClosureDate).getTime();
    const b = new Date(rowB.pinnacleClosureDate).getTime();

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}