import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tooltip } from "react-tooltip";
import DHMSFormField from './DHMSFormField';
import FormField from './FormField';
import MultipleFormField from './MultipleFormField';
import MultiSelect from './MultiSelect';
import { Link } from 'react-router-dom';
import { allMarkets, allFrenchBooks, allWorldBooks, allSports } from "../utils";

function BotParameters({ t, isAxiosReady, presetId, completePreset, executeButtonText, handleExecute, handleInitiated, additionallyButtons }) {
    const betMethods = [
        { value: "flat", label: t("Flat") },
        { value: "odd", label: t("Selon la hauteur de cotes") },
        { value: "ev", label: t("Selon la hauteur d'EV") },
        { value: "kelly", label: t("Selon le critère de Kelly") },
    ];
    const FOCalculationMethods = [
        { value: "MPTO", label: t("Margin proportional to odds (MPTO)") },
        { value: "EM", label: t("Equal margin (EM)") },
        { value: "bestOf", label: t("EM & MPTO (plus haute FO retenue)") },
        { value: "worstOf", label: t("EM & MPTO (plus basse FO retenue)") },
    ];
    const valuePriorizationMethods = [
        { value: "smallestOdd", label: t("Plus petite cote") },
        { value: "bestValue", label: t("Plus gros TRJ+EV") },
        { value: "bestLiquidity", label: t("Plus grosse liquidité") }
    ];
    const sameEventValuesTreatmentsMethods = [
        { value: "oneValueByMarketGroup", label: t("Plusieurs issues par groupe de marchés") },
        { value: "oneValueByMarketGroupAndByBook", label: t("Plusieurs issues par groupe de marchés mais un seul pari par book") },
        { value: "oneValueByMarket", label: t("Plusieurs issues par marché") },
        { value: "oneValueByMarketAndByBook", label: t("Plusieurs issues par marché mais un seul pari par book") },
        { value: "oneValueByEvent", label: t("Un pari par évènement") }
    ];
    const oddReferences = [
        { value: "softOdd", label: t("Cote du soft bookmaker") },
        { value: "FO", label: t("FO de Pinnacle") }
    ];

    const [isSetup, setIsSetup] = useState(false);
    const [subscriptionName, setSubscriptionName] = useState();
    const [allBooks, setAllBooks] = useState([]);

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [isDefaultPreset, setIsDefaultPreset] = useState();
    const [isInitiallyDefaultPreset, setIsInitiallyDefaultPreset] = useState();

    const [condition_sports, setCondition_sports] = useState();
    const [condition_markets, setCondition_markets] = useState();
    const [condition_books, setCondition_books] = useState();
    const [isConditionActive_minTRJ, setIsConditionActive_minTRJ] = useState();
    const [condition_minTRJ, setCondition_minTRJ] = useState();
    const [isConditionActive_maxTRJ, setIsConditionActive_maxTRJ] = useState();
    const [condition_maxTRJ, setCondition_maxTRJ] = useState();
    const [isConditionActive_minEV, setIsConditionActive_minEV] = useState();
    const [condition_minEV, setCondition_minEV] = useState();
    const [isConditionActive_maxEV, setIsConditionActive_maxEV] = useState();
    const [condition_maxEV, setCondition_maxEV] = useState();
    const [isConditionActive_minTRJPlusEV, setIsConditionActive_minTRJPlusEV] = useState();
    const [condition_minTRJPlusEV, setCondition_minTRJPlusEV] = useState();
    const [isConditionActive_maxTRJPlusEV, setIsConditionActive_maxTRJPlusEV] = useState();
    const [condition_maxTRJPlusEV, setCondition_maxTRJPlusEV] = useState();
    const [isConditionActive_minLiquidity, setIsConditionActive_minLiquidity] = useState();
    const [condition_minLiquidity, setCondition_minLiquidity] = useState();
    const [isConditionActive_maxLiquidity, setIsConditionActive_maxLiquidity] = useState();
    const [condition_maxLiquidity, setCondition_maxLiquidity] = useState();
    const [isConditionActive_minOdd, setIsConditionActive_minOdd] = useState();
    const [condition_minOdd, setCondition_minOdd] = useState();
    const [isConditionActive_maxOdd, setIsConditionActive_maxOdd] = useState();
    const [condition_maxOdd, setCondition_maxOdd] = useState();
    const [isConditionActive_minTimeSinceOpening, setIsConditionActive_minTimeSinceOpening] = useState();
    const [condition_minTimeSinceOpening, setCondition_minTimeSinceOpening] = useState();
    const [isConditionActive_maxTimeSinceOpening, setIsConditionActive_maxTimeSinceOpening] = useState();
    const [condition_maxTimeSinceOpening, setCondition_maxTimeSinceOpening] = useState();
    const [isConditionActive_minTimeBeforeClosing, setIsConditionActive_minTimeBeforeClosing] = useState();
    const [condition_minTimeBeforeClosing, setCondition_minTimeBeforeClosing] = useState();
    const [isConditionActive_maxTimeBeforeClosing, setIsConditionActive_maxTimeBeforeClosing] = useState();
    const [condition_maxTimeBeforeClosing, setCondition_maxTimeBeforeClosing] = useState();

    const [FOCalculationMethod, setFOCalculationMethod] = useState();
    const [valuePriorizationMethod, setValuePriorizationMethod] = useState();
    const [minTRJ, setMinTRJ] = useState();
    const [minTRJPlusEV, setMinTRJPlusEV] = useState();
    const [minEV, setMinEV] = useState();
    const [liquidity, setLiquidity] = useState();
    const [minOdd, setMinOdd] = useState();
    const [maxOdd, setMaxOdd] = useState();
    const [sameEventValuesTreatment, setSameEventValuesTreatment] = useState();
    const [onlyMajorLeagues, setOnlyMajorLeagues] = useState();
    const [minTimeBeforeOfferAgainMatch, setMinTimeBeforeOfferAgainMatch] = useState();
    const [minTimeSinceOpening, setMinTimeSinceOpening] = useState();
    const [maxTimeBeforeClosing, setMaxTimeBeforeClosing] = useState();
    const [excludedSports, setExcludedSports] = useState();
    const [excludedMarkets, setExcludedMarkets] = useState();
    const [excludedSoftBooks, setExcludedSoftBooks] = useState();

    const [capital, setCapital] = useState();
    const [oddReference, setOddReference] = useState();
    const [betMethod, setBetMethod] = useState("odd");
    const [oddMethodStep, setOddMethodStep] = useState();
    const [oddMethodMax, setOddMethodMax] = useState();
    const [EVMethodStep, setEVMethodStep] = useState();
    const [EVMethodMax, setEVMethodMax] = useState();

    const [betIndications, setBetIndications] = useState();
    const handleBetIndicationsUpdate = (method, newData) => {
        switch (method) {
            case "flat":
                setBetIndications({ flat: newData, odd: betIndications.odd, ev: betIndications.ev, kelly: betIndications.kelly });
                break;
            case "odd":
                setBetIndications({ flat: betIndications.flat, odd: newData, ev: betIndications.ev, kelly: betIndications.kelly });
                break;
            case "ev":
                setBetIndications({ flat: betIndications.flat, odd: betIndications.odd, ev: newData, kelly: betIndications.kelly });
                break;
            case "kelly":
                setBetIndications({ flat: betIndications.flat, odd: betIndications.odd, ev: betIndications.ev, kelly: newData });
                break;
            default:
                break;
        }
    }

    const handleExecuteClicked = (e) => {
        e.preventDefault();

        handleExecute(getFormattedParameters());
    }

    const getFormattedParameters = () => {
        let filters = {
            id: presetId,
            subscriptionName,
            FOCalculationMethod,
            valuePriorizationMethod,
            minTRJ: parseFloat(minTRJ),
            minTRJPlusEV: parseFloat(minTRJPlusEV),
            minEV: parseFloat(minEV),
            liquidity,
            minOdd,
            maxOdd,
            sameEventValuesTreatment,
            onlyMajorLeagues,
            minTimeBeforeOfferAgainMatch,
            minTimeSinceOpening,
            maxTimeBeforeClosing,
            excludedMarkets: excludedMarkets && excludedMarkets[0] && excludedMarkets[0].value ? excludedMarkets.map(a => a.value).join(", ") : excludedMarkets.join(", "),
            excludedSports: excludedSports && excludedSports[0] && excludedSports[0].value ? excludedSports.map(a => a.value).join(", ") : excludedSports.join(", "),
            excludedSoftBooks: excludedSoftBooks && excludedSoftBooks[0] && excludedSoftBooks[0].value ? excludedSoftBooks.map(a => a.value).join(", ") : excludedSoftBooks.join(", "),
            capital,
            oddReference,
            betMethod,
            oddMethodStep,
            oddMethodMax,
            EVMethodStep,
            EVMethodMax,
            betIndications
        }

        if (completePreset) {
            filters = {
                ...filters,
                title,
                description,
                isDefaultPreset,
                condition_sports: condition_sports && condition_sports[0] && condition_sports[0].value ? condition_sports.map(a => a.value).join(", ") : condition_sports.join(", "),
                condition_markets: condition_markets && condition_markets[0] && condition_markets[0].value ? condition_markets.map(a => a.value).join(", ") : condition_markets.join(", "),
                condition_books: condition_books && condition_books[0] && condition_books[0].value ? condition_books.map(a => a.value).join(", ") : condition_books.join(", "),
                isConditionActive_minTRJ,
                condition_minTRJ,
                isConditionActive_maxTRJ,
                condition_maxTRJ,
                isConditionActive_minEV,
                condition_minEV,
                isConditionActive_maxEV,
                condition_maxEV,
                isConditionActive_minTRJPlusEV,
                condition_minTRJPlusEV,
                isConditionActive_maxTRJPlusEV,
                condition_maxTRJPlusEV,
                isConditionActive_minLiquidity,
                condition_minLiquidity,
                isConditionActive_maxLiquidity,
                condition_maxLiquidity,
                isConditionActive_minOdd,
                condition_minOdd,
                isConditionActive_maxOdd,
                condition_maxOdd,
                isConditionActive_minTimeSinceOpening,
                condition_minTimeSinceOpening,
                isConditionActive_maxTimeSinceOpening,
                condition_maxTimeSinceOpening,
                isConditionActive_minTimeBeforeClosing,
                condition_minTimeBeforeClosing,
                isConditionActive_maxTimeBeforeClosing,
                condition_maxTimeBeforeClosing
            }
        }

        return filters;
    }

    useEffect(() => {
        if (isAxiosReady && !isSetup) {
            try {
                if (handleInitiated)
                    handleInitiated(getFormattedParameters());

                setIsSetup(true);
            }
            catch (error) {
                console.log(error);
            }
        }
    }, [title, description, isDefaultPreset, condition_sports, condition_markets, condition_books, condition_minTRJ, condition_maxTRJ, condition_minEV, condition_maxEV, condition_minTRJPlusEV, condition_maxTRJPlusEV, condition_minLiquidity, condition_maxLiquidity, condition_minOdd, condition_maxOdd, condition_minTimeSinceOpening, condition_maxTimeSinceOpening, condition_minTimeBeforeClosing, condition_maxTimeBeforeClosing, FOCalculationMethod, valuePriorizationMethod, minTRJ, minTRJPlusEV, minEV, liquidity, minOdd, maxOdd, sameEventValuesTreatment, onlyMajorLeagues, minTimeBeforeOfferAgainMatch, minTimeSinceOpening, maxTimeBeforeClosing, excludedMarkets, excludedSports, excludedSoftBooks, capital, oddReference, betMethod, oddMethodStep, oddMethodMax, EVMethodStep, EVMethodMax, betIndications]);

    useEffect(() => {
        if (isAxiosReady) {
            axios.get("/robot/" + presetId).then(async (response) => {
                if (response.data) {
                    setIsSetup(false);
                    setSubscriptionName(response.data.subscriptionName);
                    const tmpAllBooks = response.data.subscriptionName === "BSM-FRANCE" ? allFrenchBooks : allWorldBooks;
                    const tmpAllFlattenBooks = Object.values(tmpAllBooks).flat();
                    setAllBooks(tmpAllBooks);
                    setTitle(response.data.title);
                    setDescription(response.data.description);
                    setIsDefaultPreset(response.data.isDefaultPreset);
                    setIsInitiallyDefaultPreset(response.data.isDefaultPreset);
                    setCondition_sports(response.data.condition_sports ? response.data.condition_sports.split(", ").map(sport => allSports.find(model => model.value === sport)) : []);
                    setCondition_markets(response.data.condition_markets ? response.data.condition_markets.split(", ").map(market => allMarkets.find(group => group.options.find(model => model.value === market))?.options.find(model => model.value === market)) : []);
                    setCondition_books(response.data.condition_books ? response.data.condition_books.split(", ").map(book => tmpAllFlattenBooks.find(model => model.value === book)) : []);
                    setIsConditionActive_minTRJ(response.data.isConditionActive_minTRJ ? response.data.isConditionActive_minTRJ : false);
                    setCondition_minTRJ(response.data.condition_minTRJ ? response.data.condition_minTRJ : 99);
                    setIsConditionActive_maxTRJ(response.data.isConditionActive_maxTRJ ? response.data.isConditionActive_maxTRJ : false);
                    setCondition_maxTRJ(response.data.condition_maxTRJ ? response.data.condition_maxTRJ : 99);
                    setIsConditionActive_minEV(response.data.isConditionActive_minEV ? response.data.isConditionActive_minEV : false);
                    setCondition_minEV(response.data.condition_minEV ? response.data.condition_minEV : 1);
                    setIsConditionActive_maxEV(response.data.isConditionActive_maxEV ? response.data.isConditionActive_maxEV : false);
                    setCondition_maxEV(response.data.condition_maxEV ? response.data.condition_maxEV : 1);
                    setIsConditionActive_minTRJPlusEV(response.data.isConditionActive_minTRJPlusEV ? response.data.isConditionActive_minTRJPlusEV : false);
                    setCondition_minTRJPlusEV(response.data.condition_minTRJPlusEV ? response.data.condition_minTRJPlusEV : 100);
                    setIsConditionActive_maxTRJPlusEV(response.data.isConditionActive_maxTRJPlusEV ? response.data.isConditionActive_maxTRJPlusEV : false);
                    setCondition_maxTRJPlusEV(response.data.condition_maxTRJPlusEV ? response.data.condition_maxTRJPlusEV : 100);
                    setIsConditionActive_minLiquidity(response.data.isConditionActive_minLiquidity ? response.data.isConditionActive_minLiquidity : false);
                    setCondition_minLiquidity(response.data.condition_minLiquidity ? response.data.condition_minLiquidity : 50);
                    setIsConditionActive_maxLiquidity(response.data.isConditionActive_maxLiquidity ? response.data.isConditionActive_maxLiquidity : false);
                    setCondition_maxLiquidity(response.data.condition_maxLiquidity ? response.data.condition_maxLiquidity : 50);
                    setIsConditionActive_minOdd(response.data.isConditionActive_minOdd ? response.data.isConditionActive_minOdd : false);
                    setCondition_minOdd(response.data.condition_minOdd ? response.data.condition_minOdd : 1);
                    setIsConditionActive_maxOdd(response.data.isConditionActive_maxOdd ? response.data.isConditionActive_maxOdd : false);
                    setCondition_maxOdd(response.data.condition_maxOdd ? response.data.condition_maxOdd : 6);
                    setIsConditionActive_minTimeSinceOpening(response.data.isConditionActive_minTimeSinceOpening ? response.data.isConditionActive_minTimeSinceOpening : false);
                    setCondition_minTimeSinceOpening(response.data.condition_minTimeSinceOpening ? response.data.condition_minTimeSinceOpening : 0);
                    setIsConditionActive_maxTimeSinceOpening(response.data.isConditionActive_maxTimeSinceOpening ? response.data.isConditionActive_maxTimeSinceOpening : false);
                    setCondition_maxTimeSinceOpening(response.data.condition_maxTimeSinceOpening ? response.data.condition_maxTimeSinceOpening : 0);
                    setIsConditionActive_minTimeBeforeClosing(response.data.isConditionActive_minTimeBeforeClosing ? response.data.isConditionActive_minTimeBeforeClosing : false);
                    setCondition_minTimeBeforeClosing(response.data.condition_minTimeBeforeClosing ? response.data.condition_minTimeBeforeClosing : 0);
                    setIsConditionActive_maxTimeBeforeClosing(response.data.isConditionActive_maxTimeBeforeClosing ? response.data.isConditionActive_maxTimeBeforeClosing : false);
                    setCondition_maxTimeBeforeClosing(response.data.condition_maxTimeBeforeClosing ? response.data.condition_maxTimeBeforeClosing : 0);

                    setFOCalculationMethod(response.data.FOCalculationMethod);
                    setValuePriorizationMethod(response.data.valuePriorizationMethod);
                    setMinTRJ(response.data.minTRJ);
                    setMinTRJPlusEV(response.data.minTRJPlusEV);
                    setMinEV(response.data.minEV);
                    setLiquidity(response.data.liquidity);
                    setMinOdd(response.data.minOdd);
                    setMaxOdd(response.data.maxOdd);
                    setSameEventValuesTreatment(response.data.sameEventValuesTreatment);
                    setOnlyMajorLeagues(response.data.onlyMajorLeagues);
                    setMinTimeBeforeOfferAgainMatch(response.data.minTimeBeforeOfferAgainMatch);
                    setMinTimeSinceOpening(response.data.minTimeSinceOpening);
                    setMaxTimeBeforeClosing(response.data.maxTimeBeforeClosing);
                    setExcludedSports(response.data.excludedSports.split(", ").map(sport => allSports.find(model => model.value === sport)));
                    setExcludedMarkets(response.data.excludedMarkets.split(", ").map(market => allMarkets.find(group => group.options.find(model => model.value === market))?.options.find(model => model.value === market)));
                    setExcludedSoftBooks(response.data.excludedSoftBooks.split(", ").map(soft => tmpAllFlattenBooks.find(model => model.value === soft)));

                    setCapital(response.data.capital);
                    setOddReference(response.data.oddReference);
                    setBetMethod(response.data.betMethod);
                    setOddMethodStep(response.data.oddMethodStep);
                    setOddMethodMax(response.data.oddMethodMax);
                    setEVMethodStep(response.data.EVMethodStep);
                    setEVMethodMax(response.data.EVMethodMax);
                    //setBetIndications(/*JSON.parse(*/response.data.betIndications/*)*/);
                    setBetIndications(JSON.parse(response.data.betIndications));
                }
            }).catch(err => {
                //console.log(err);
            });
        }
    }, [isAxiosReady, presetId]);

    if (isSetup) {
        return (
            <>
                {completePreset && (
                    <form id="presetConfig">
                        <Link className="backToPresets" to="/presets">{"<"} {t("Revenir à la liste des presets")}</Link>

                        <fieldset id="general">
                            <legend>{t("Votre preset")}</legend>

                            <FormField t={t} id="isDefaultPreset" type="checkbox" disabled={isInitiallyDefaultPreset} label={t("Est votre configuration par défaut")} checked={isDefaultPreset} onChange={setIsDefaultPreset} />
                            {!isInitiallyDefaultPreset && isDefaultPreset && <h6 className="alert">{t("Attention ! En changeant votre configuration par défaut, la précédente ne sera plus considérée comme \"par défaut\" et sera ainsi à nouveau utilisée selon ses conditions de sélection de preset !")}</h6>}
                            <FormField t={t} id="title" type="text" label={t("Titre")} value={title} onChange={setTitle} />
                            <FormField t={t} id="description" type="textarea" label={t("Description")} value={description} onChange={setDescription} />
                        </fieldset>

                        <fieldset id="conditions">
                            <legend>{t("Conditions de sélection de ce preset")}</legend>

                            {isDefaultPreset ?
                                <h4 className="alert">{t("Aucune condition ne peut être appliquée à votre configuration par défaut !")}</h4>
                                : (
                                    <>
                                        <p className="alert"><strong>{t("Les conditions de sélection permettent de déterminer si le preset courant sera considéré ou non pour une value donnée. Si l'ensemble des conditions actives sont satisfaites, le preset est considéré comme valable et la value est donc filtrée et recalculée selon les critères de filtrages que vous paramétrerez dans le prochain encadré !")}</strong></p>

                                        <Tooltip anchorSelect="#tooltip_conditionTRJ">{t("Taux de retour joueur (TRJ) minimum et maximum de la cote du soft bookmaker et de celle(s) de Pinnacle.")}</Tooltip>
                                        <Tooltip anchorSelect="#tooltip_conditionEV">{t("Expected value (EV) minimale et maximale de la cote du soft bookmaker par rapport à la cote sans marge (fair odd) de Pinnacle.")}</Tooltip>
                                        <Tooltip anchorSelect="#tooltip_conditionLiquidity">{t("Limites de mises maximales chez Pinnacle.")}</Tooltip>

                                        <FormField t={t} id="condition_markets" type="select" options={allMarkets} multiple={true} label={t("Marchés")} value={condition_markets} onChange={setCondition_markets} />
                                        <FormField t={t} id="condition_sports" type="select" options={allSports} multiple={true} label={t("Sports")} value={condition_sports} onChange={setCondition_sports} />
                                        <MultiSelect t={t} id="condition_books" options={allBooks} label={t("Bookmakers")} value={condition_books} onChange={setCondition_books} />

                                        <FormField t={t} id="FOCalculationMethod" tooltip="tooltip_FOCalculationMethod" type="select" label={t("Méthode de calcul de la FO")} options={FOCalculationMethods} value={FOCalculationMethod} onChange={setFOCalculationMethod} />
                                        <MultipleFormField t={t} id={["condition_minTRJ", "condition_maxTRJ"]} tooltip={["tooltip_conditionTRJ", ""]} min={[80, 80]} conditionable={[true, true]} disabled={[!isConditionActive_minTRJ, !isConditionActive_maxTRJ]} setDisabled={[setIsConditionActive_minTRJ, setIsConditionActive_maxTRJ]} step={["0.1", "0.1"]} type={["number", "number"]} label={[t("TRJ min"), t(" / max ")]} value={[condition_minTRJ, condition_maxTRJ]} onChange={[setCondition_minTRJ, setCondition_maxTRJ]} placeholder={["TRJ min", "TRJ max"]} afterLabel={["%", "%"]} />
                                        <MultipleFormField t={t} id={["condition_minEV", "condition_maxEV"]} tooltip={["tooltip_conditionEV", ""]} min={[0, 0]} conditionable={[true, true]} disabled={[!isConditionActive_minEV, !isConditionActive_maxEV]} setDisabled={[setIsConditionActive_minEV, setIsConditionActive_maxEV]} step={["0.1", "0.1"]} type={["number", "number"]} label={[t("EV min"), t(" / max ")]} value={[condition_minEV, condition_maxEV]} onChange={[setCondition_minEV, setCondition_maxEV]} placeholder={["EV min", "EV max"]} afterLabel={["%", "%"]} />
                                        <MultipleFormField t={t} id={["condition_minTRJPlusEV", "condition_maxTRJPlusEV"]} min={[80, 80]} conditionable={[true, true]} disabled={[!isConditionActive_minTRJPlusEV, !isConditionActive_maxTRJPlusEV]} setDisabled={[setIsConditionActive_minTRJPlusEV, setIsConditionActive_maxTRJPlusEV]} step={["0.1", "0.1"]} type={["number", "number"]} label={[t("TRJ+EV min"), t(" / max ")]} value={[condition_minTRJPlusEV, condition_maxTRJPlusEV]} onChange={[setCondition_minTRJPlusEV, setCondition_maxTRJPlusEV]} placeholder={["TRJ+EV min", "TRJ+EV max"]} afterLabel={["%", "%"]} />
                                        <MultipleFormField t={t} id={["condition_minOdd", "condition_maxOdd"]} min={[1, 1]} max={[20, 20]} conditionable={[true, true]} disabled={[!isConditionActive_minOdd, !isConditionActive_maxOdd]} setDisabled={[setIsConditionActive_minOdd, setIsConditionActive_maxOdd]} step={["0.01", "0.01"]} type={["number", "number"]} label={[t("Cote min"), t(" / max ")]} value={[condition_minOdd, condition_maxOdd]} onChange={[setCondition_minOdd, setCondition_maxOdd]} placeholder={["Cote min", "Cote max"]} />
                                        <MultipleFormField t={t} id={["condition_minLiquidity", "condition_maxLiquidity"]} tooltip={["tooltip_conditionLiquidity", ""]} min={[1, 1]} max={[10000, 100000]} conditionable={[true, true]} disabled={[!isConditionActive_minLiquidity, !isConditionActive_maxLiquidity]} setDisabled={[setIsConditionActive_minLiquidity, setIsConditionActive_maxLiquidity]} step={["1", "1"]} type={["number", "number"]} label={[t("Liquidité min"), t(" / max ")]} value={[condition_minLiquidity, condition_maxLiquidity]} onChange={[setCondition_minLiquidity, setCondition_maxLiquidity]} placeholder={["Liquidité min", "Liquidité max"]} />

                                        <DHMSFormField t={t} className="fullWidth" id="condition_minTimeSinceOpening" conditionable={true} disabled={!isConditionActive_minTimeSinceOpening} setDisabled={setIsConditionActive_minTimeSinceOpening} label={t("Temps minimal depuis l'opening des cotes")} value={condition_minTimeSinceOpening} onChange={setCondition_minTimeSinceOpening} />
                                        <DHMSFormField t={t} className="fullWidth" id="condition_maxTimeSinceOpening" conditionable={true} disabled={!isConditionActive_maxTimeSinceOpening} setDisabled={setIsConditionActive_maxTimeSinceOpening} label={t("Temps maximal depuis l'opening des cotes")} value={condition_maxTimeSinceOpening} onChange={setCondition_maxTimeSinceOpening} />
                                        <DHMSFormField t={t} className="fullWidth" id="condition_minTimeBeforeClosing" conditionable={true} disabled={!isConditionActive_minTimeBeforeClosing} setDisabled={setIsConditionActive_minTimeBeforeClosing} label={t("Temps minimal avant le closing des cotes")} value={condition_minTimeBeforeClosing} onChange={setCondition_minTimeBeforeClosing} />
                                        <DHMSFormField t={t} className="fullWidth" id="condition_maxTimeBeforeClosing" conditionable={true} disabled={!isConditionActive_maxTimeBeforeClosing} setDisabled={setIsConditionActive_maxTimeBeforeClosing} label={t("Temps maximal avant le closing des cotes")} value={condition_maxTimeBeforeClosing} onChange={setCondition_maxTimeBeforeClosing} />
                                    </>
                                )
                            }
                        </fieldset>
                    </form>
                )}

                <form id="botParameters">
                    <Tooltip anchorSelect="#tooltip_FOCalculationMethod">{t("Les fair odds (FO) peuvent être significativement différentes d'une méthode de calcul à l'autre.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_valuePriorizationMethod">{t("Prioriser les marchés lorsque plusieurs value bets, détectés simultanément, proviennent de marchés fortement corrélés.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_minTRJ">{t("Taux de retour joueur (TRJ) minimum de la cote du soft bookmaker et de celle(s) de Pinnacle.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_minEV">{t("Expected value (EV) minimale de la cote du soft bookmaker par rapport à la cote sans marge (fair odd) de Pinnacle.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_liquidity">{t("Limites de mises maximales chez Pinnacle.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_minTimeBeforeOfferAgainMatch">{t("Fin équilibre entre s'assurer de ne manquer aucun value bet sans pour autant être spammé.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_minTimeSinceOpening">{t("Durée minimale à partir de l'ouverture de la cote chez Pinnacle à partir de laquelle la comparaison peut être faite.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_maxTimeBeforeClosing">{t("Durée maximale permise avant le coup d'envoi des rencontres.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_excludedMarkets">{t("Marchés sortant du champ de comparaison.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_excludedSports">{t("Sports sortant du champ de comparaison.")}</Tooltip>
                    <Tooltip anchorSelect="#tooltip_sameEventValuesTreatment">{t("Méthode de traitement des paris multiples pour un même évènement.")}</Tooltip>

                    <fieldset id="filtersParams">
                        {completePreset && (
                            <p className="alert"><strong>{t("Une fois toutes les conditions satisfaites, la value passe par l'intégralité des critères ci-dessous et se recalcule à plusieurs reprises afin d'être parfaitement en adéquation avec vos critères de filtrage !")}</strong></p>
                        )}

                        <legend>{t("Critères de filtrage des values")}</legend>
                        <FormField t={t} id="onlyMajorLeagues" type="checkbox" label={t("Uniquement les ligues majeures")} checked={onlyMajorLeagues} onChange={setOnlyMajorLeagues} />
                        {(!completePreset || isDefaultPreset) && <FormField t={t} id="FOCalculationMethod" tooltip="tooltip_FOCalculationMethod" type="select" label={t("Méthode de calcul de la FO")} options={FOCalculationMethods} value={FOCalculationMethod} onChange={setFOCalculationMethod} />}
                        <FormField t={t} id="liquidity" tooltip="tooltip_liquidity" type="number" min="1" max="10000" label={t("Liquidité minimale")} value={liquidity} onChange={setLiquidity} afterLabel={t("€")} />
                        <FormField t={t} id="minTRJ" tooltip="tooltip_minTRJ" type="number" step="0.1" min="80" label={t("TRJ minimal")} afterLabel="%" value={minTRJ} onChange={setMinTRJ} />
                        <FormField t={t} id="minEV" tooltip="tooltip_minEV" type="number" step="0.1" min="0" label={t("EV minimale")} afterLabel="%" value={minEV} onChange={setMinEV} />
                        <FormField t={t} id="minTRJPlusEV" type="number" step="0.1" min="80" label={t("TRJ+EV minimal")} afterLabel="%" value={minTRJPlusEV} onChange={setMinTRJPlusEV} />
                        <MultipleFormField t={t} id={["minOdd", "maxOdd"]} min={[1, 1]} max={[20, 20]} step={["0.01", "0.01"]} type={["number", "number"]} label={[t("Cote allant de"), t(" à ")]} value={[minOdd, maxOdd]} onChange={[setMinOdd, setMaxOdd]} placeholder={["Cote min", "Cote max"]} />
                        <FormField t={t} id="excludedMarkets" tooltip="tooltip_excludedMarkets" type="select" options={allMarkets} multiple={true} label={t("Marchés exclus")} value={excludedMarkets} onChange={setExcludedMarkets} />
                        <FormField t={t} id="excludedSports" tooltip="tooltip_excludedSports" type="select" options={allSports} multiple={true} label={t("Sports exclus")} value={excludedSports} onChange={setExcludedSports} />
                        <MultiSelect t={t} id="excludedSoftBooks" options={allBooks} label={t("Bookmakers exclus")} value={excludedSoftBooks} onChange={setExcludedSoftBooks} />
                        {completePreset && <FormField t={t} id="valuePriorizationMethod" tooltip="tooltip_valuePriorizationMethod" type="select" label={t("Méthode de priorisation")} options={valuePriorizationMethods} value={valuePriorizationMethod} onChange={setValuePriorizationMethod} />}
                        <FormField t={t} id="sameEventValuesTreatment" type="select" tooltip="tooltip_sameEventValuesTreatment" label={t("Paris multiples par évènement")} options={sameEventValuesTreatmentsMethods} value={sameEventValuesTreatment} onChange={setSameEventValuesTreatment} />

                        <DHMSFormField t={t} className="fullWidth" id="minTimeBeforeOfferAgainMatch" tooltip="tooltip_minTimeBeforeOfferAgainMatch" label={t("Temps minimal entre deux notifications de la même issue d'un même match")} value={minTimeBeforeOfferAgainMatch} onChange={setMinTimeBeforeOfferAgainMatch} />
                        <DHMSFormField t={t} className="fullWidth" id="minTimeSinceOpening" tooltip="tooltip_minTimeSinceOpening" label={t("Temps minimal depuis l'opening des cotes sur Pinnacle")} value={minTimeSinceOpening} onChange={setMinTimeSinceOpening} />
                        <DHMSFormField t={t} className="fullWidth" id="maxTimeBeforeClosing" tooltip="tooltip_maxTimeBeforeClosing" label={t("Temps maximal avant le closing des cotes sur Pinnacle")} value={maxTimeBeforeClosing} onChange={setMaxTimeBeforeClosing} />
                    </fieldset>

                    <fieldset id="moneyParams">
                        <legend>{t("Gestion de mises")}</legend>
                        <FormField t={t} id="capital" min="1" type="number" label={t("Capital")} value={capital} onChange={setCapital} />
                        <FormField t={t} id="oddReference" type="select" label={t("Cote de référence")} options={oddReferences} value={oddReference} onChange={setOddReference} />
                        <FormField t={t} id="betMethod" type="select" label={t("Méthode de mises")} options={betMethods} value={betMethod} onChange={setBetMethod} />

                        <FlatArray t={t} data={betIndications.hasOwnProperty("flat") ? betIndications["flat"] : 0.01} onChange={(newData) => handleBetIndicationsUpdate("flat", newData)} capital={capital} visible={betMethod === "flat"} />
                        <KellyArray t={t} data={betIndications["kelly"]} onChange={(newData) => handleBetIndicationsUpdate("kelly", newData)} visible={betMethod === "kelly"} />
                        <OddsArray t={t} data={betIndications["odd"]} onChange={(newData) => handleBetIndicationsUpdate("odd", newData)} capital={capital} visible={betMethod === "odd"} oddMethodStep={oddMethodStep} setOddMethodStep={setOddMethodStep} oddMethodMax={oddMethodMax} setOddMethodMax={setOddMethodMax} />
                        <EVsArray t={t} data={betIndications["ev"]} onChange={(newData) => handleBetIndicationsUpdate("ev", newData)} capital={capital} visible={betMethod === "ev"} EVMethodStep={EVMethodStep} setEVMethodStep={setEVMethodStep} EVMethodMax={EVMethodMax} setEVMethodMax={setEVMethodMax} />
                    </fieldset>

                    <div className="buttonsContainer">
                        <button className="btn thematized" onClick={handleExecuteClicked}>{executeButtonText}</button>
                        {additionallyButtons && additionallyButtons.map((button, index) => <button key={index} className={"btn " + button.class} onClick={button.action}>{button.text}</button>)}
                    </div>
                </form>
            </>
        )
    }
    else {
        return <></>
    }
}

export default BotParameters;

function FlatArray({ t, data, visible, capital, onChange }) {
    const handlePercentageUpdate = (newValue) => {
        let newData = parseFloat((newValue / 100).toFixed(4));
        onChange(newData);
    }

    // Used to set the default value as it's a new method
    useEffect(() => {
        handlePercentageUpdate(data * 100);
    }, [visible]);

    return (
        <div id="betMethod-flat" className={"betMethodContainer" + (visible ? "" : " hidden")}>
            <table id="flatTable">
                <thead>
                    <tr>
                        <th>{t("% de capital à parier")}</th>
                        <th>{t("Mise correspondante")}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><input type="number" step="0.01" value={parseFloat((data * 100).toFixed(4))} onChange={e => handlePercentageUpdate(e.target.value)} />%</td>
                        <td>{Math.floor(data * capital)}{t("€")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

function KellyArray({ t, data, visible, onChange }) {
    let lines = [];
    for (let lineIndex = 0; lineIndex < data[0].odd.length; lineIndex++) {
        const currentLine = data[lineIndex];
        lines.push([]);

        if (!currentLine.ev.max)
            lines[lineIndex].push('y>' + parseInt(currentLine.ev.min * 100) + '%');
        else
            lines[lineIndex].push(parseInt(currentLine.ev.min * 100) + '%<y≤' + parseInt(currentLine.ev.max * 100) + '%');

        for (let columnIndex = 0; columnIndex < data.length; columnIndex++) {
            const currentColumn = currentLine.odd[columnIndex];
            lines[lineIndex].push((currentColumn.percentage * 100).toFixed(3));
        }
    }

    const handlePercentageUpdate = (lineIndex, columnIndex, newValue) => {
        let newData = data;

        newData[lineIndex].odd[columnIndex - 1].percentage = newValue / 100;

        onChange(newData);
    }

    return (
        <div id="betMethod-kelly" className={"betMethodContainer" + (visible ? "" : " hidden")}>
            <table id="kellyTable">
                <thead>
                    <tr>
                        <th>{t("EV")} (y) / {t("Cote")} (x)</th>
                        {
                            data.map((info, index) => {
                                if (!info.odd[index].max)
                                    return <th key={index}>{"x>" + info.odd[index].min.toString().replace(".", ",")}</th>
                                else
                                    return <th key={index}>{info.odd[index].min.toString().replace(".", ",") + "<x≤" + info.odd[index].max.toString().replace(".", ",")}</th>
                            })
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        lines.map((line, lineIndex) =>
                            <tr key={lineIndex}>
                                {
                                    line.map((cell, cellIndex) => {
                                        if (cellIndex === 0)
                                            return <th key={cellIndex}>{cell}</th>
                                        else
                                            return <td key={cellIndex}><input type="number" step="0.001" value={cell} onChange={e => handlePercentageUpdate(lineIndex, cellIndex, e.target.value)} />%</td>
                                    })
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

function OddsArray({ t, data, visible, capital, oddMethodStep, setOddMethodStep, oddMethodMax, setOddMethodMax, onChange }) {
    const handleChangeOddStep = (newValue) => {
        newValue = parseFloat(newValue);

        let newData = [];
        let i = 0;
        let stop = false;
        let currentOdd = 1;
        while (!stop) {
            let nextMax = parseFloat((currentOdd + newValue).toFixed(1));

            if (currentOdd >= oddMethodMax) {
                newData.push({ min: oddMethodMax, percentage: data.length > i ? data[i].percentage : 0 });
                stop = true;
            }
            else
                newData.push({ min: currentOdd, max: nextMax >= oddMethodMax ? oddMethodMax : nextMax, percentage: data.length > i ? data[i].percentage : 0 });

            i++;
            currentOdd = nextMax;
        }

        setOddMethodStep(newValue);
        onChange(newData);
    }

    const handleChangeOddMax = (newValue, direct = false) => {
        if (!direct)
            newValue = newValue > oddMethodMax ? parseFloat((oddMethodMax + oddMethodStep).toFixed(3)) : parseFloat((oddMethodMax - oddMethodStep).toFixed(3));
        else
            newValue = parseFloat(newValue.toFixed(3))

        let newData = [];
        let i = 0;
        let stop = false;
        let currentOdd = 1;
        while (!stop) {
            let nextMax = parseFloat((currentOdd + oddMethodStep).toFixed(1));

            if (currentOdd >= newValue) {
                newData.push({ min: newValue, percentage: data.length > i ? data[i].percentage : 0 });
                stop = true;
            }
            else
                newData.push({ min: currentOdd, max: nextMax >= newValue ? newValue : nextMax, percentage: data.length > i ? data[i].percentage : 0 });

            i++;
            currentOdd = nextMax;
        }

        setOddMethodMax(newValue);
        onChange(newData);
    }

    const handlePercentageUpdate = (index, newValue) => {
        let newData = data;
        newData[index].percentage = parseFloat((newValue / 100).toFixed(4));
        onChange(newData);
    }

    useEffect(() => {
        handleChangeOddMax(roundTo(1, oddMethodMax, oddMethodStep), true);
    }, [oddMethodStep]);

    return (
        <div id="betMethod-odd" className={"betMethodContainer" + (visible ? "" : " hidden")}>
            <FormField type="number" id="oddMethodStep" min="0.1" step="0.1" value={oddMethodStep} onChange={handleChangeOddStep} label={t("Intervalle")} />
            <FormField type="number" id="oddMethodMax" min="1.1" step={oddMethodStep} value={oddMethodMax} onChange={handleChangeOddMax} label={t("Cote maximale")} />

            <table id="oddTable">
                <thead>
                    <tr>
                        <th>{t("Cote")}</th>
                        <th>{t("% de capital à parier")}</th>
                        <th>{t("Mise correspondante")}</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((info, index) =>
                            <tr key={index}>
                                <th data-min={info.min} data-max={info.max}>{info.max ? (info.min.toString().replace(".", ",") + " < " + t("Cote") + " ≤ " + info.max.toString().replace(".", ",")) : (t("Cote") + " > " + info.min.toString().replace(".", ","))}</th>
                                <td><input type="number" step="0.01" value={parseFloat((info.percentage * 100).toFixed(4))} onChange={e => handlePercentageUpdate(index, e.target.value)} />%</td>
                                <td>{Math.floor(info.percentage * capital)}{t("€")}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

function EVsArray({ t, data, visible, capital, EVMethodStep, setEVMethodStep, EVMethodMax, setEVMethodMax, onChange }) {
    const handleChangeEVStep = (newValue) => {
        newValue = parseFloat(newValue);

        let newData = [];
        let i = 0;
        let stop = false;
        let currentEV = 0;
        while (!stop) {
            let nextMax = parseFloat((currentEV + newValue).toFixed(1));

            if (currentEV >= EVMethodMax) {
                newData.push({ min: EVMethodMax, percentage: data.length > i ? data[i].percentage : 0 });
                stop = true;
            }
            else
                newData.push({ min: currentEV, max: nextMax >= EVMethodMax ? EVMethodMax : nextMax, percentage: data.length > i ? data[i].percentage : 0 });

            i++;
            currentEV = nextMax;
        }

        setEVMethodStep(newValue);
        onChange(newData);
    }

    const handleChangeEVMax = (newValue, direct = false) => {
        if (!direct)
            newValue = newValue > EVMethodMax ? parseFloat((EVMethodMax + EVMethodStep).toFixed(3)) : parseFloat((EVMethodMax - EVMethodStep).toFixed(3));
        else
            newValue = parseFloat(newValue.toFixed(3))

        let newData = [];
        let i = 0;
        let stop = false;
        let currentEV = 0;
        while (!stop) {
            let nextMax = parseFloat((currentEV + EVMethodStep).toFixed(1));

            if (currentEV >= newValue) {
                newData.push({ min: newValue, percentage: data.length > i ? data[i].percentage : 0 });
                stop = true;
            }
            else
                newData.push({ min: currentEV, max: nextMax >= newValue ? newValue : nextMax, percentage: data.length > i ? data[i].percentage : 0 });

            i++;
            currentEV = nextMax;
        }

        setEVMethodMax(newValue);
        onChange(newData);
    }

    const handlePercentageUpdate = (index, newValue) => {
        let newData = data;
        newData[index].percentage = parseFloat((newValue / 100).toFixed(4));
        onChange(newData);
    }

    useEffect(() => {
        handleChangeEVMax(roundTo(0, EVMethodMax, EVMethodStep), true);
    }, [EVMethodStep]);

    return (
        <div id="betMethod-ev" className={"betMethodContainer" + (visible ? "" : " hidden")}>
            <FormField type="number" id="EVMethodStep" min="0.1" step="0.1" value={EVMethodStep} onChange={handleChangeEVStep} label={t("Intervalle")} />
            <FormField type="number" id="EVMethodMax" min="1.1" step="0.1" value={EVMethodMax} onChange={handleChangeEVMax} label={t("EV maximale")} />

            <table id="evTable">
                <thead>
                    <tr>
                        <th>{t("EV")}</th>
                        <th>{t("% de capital à parier")}</th>
                        <th>{t("Mise correspondante")}</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((info, index) =>
                            <tr key={index}>
                                <th>{info.max ? (info.min.toString().replace(".", ",") + "% < " + t("EV") + " ≤ " + info.max.toString().replace(".", ",") + "%") : (t("EV") + " > " + info.min.toString().replace(".", ",") + "%")}</th>
                                <td><input type="number" step="0.01" value={parseFloat((info.percentage * 100).toFixed(4))} onChange={e => handlePercentageUpdate(index, e.target.value)} />%</td>
                                <td>{info.percentage * capital}{t("€")}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

const roundTo = (base, num, multiplier) => {
    var resto = (num - base) % multiplier;
    if (resto <= (multiplier / 2)) {
        return num - resto;
    } else {
        return num + multiplier - resto;
    }
}