import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react';
import FormField from '../components/FormField';
import toast from "react-hot-toast";
import Popup from '../components/Popup';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Profile({ t, isAxiosReady, logout, user, setUser, setJwt, setIsLoading, setLoadingText }) {
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [infoErrors, setInfoErrors] = useState([]);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [isDeleteAccountPopupOpened, setIsDeleteAccountPopupOpened] = useState(false);

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setEmail(user.email);
        }
    }, [user]);

    const handleSaveInfo = (e) => {
        e.preventDefault();

        // If nothing has changed just return
        if (email === user.email && username === user.username) {
            toast.success(t("Vos informations ont bien été sauvegardées !"));
            return;
        }

        let errors = [];
        if (!email || !username)
            errors.push(t("Veuillez saisir tous les champs"));
        else {
            if (!email.match(emailRegex))
                errors.push(t("L'email n'existe pas"));
            if (username.length < 3)
                errors.push(t("Le pseudo doit contenir au minimum 3 caractères"));
        }

        if (errors.length > 0) {
            setInfoErrors(errors);
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Mise à jour de vos informations..."));

            axios.put("/user/", { id: user.id, username, email }).then((response) => {
                toast.success(t("Vos informations ont bien été sauvegardées !"));
                setInfoErrors([]);

                // Update JWT and user with new info
                setUser(response.data.user);
                localStorage.setItem("jwt", response.data.token);
                setJwt(response.data.token);
            }).catch(err => {
                console.log(err);
                toast.error(t("Vos informations n'ont pas pu être sauvegardées !"));
                setInfoErrors(err.response.data.errors);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleSavePassword = (e) => {
        e.preventDefault();

        let errors = []
        if (!oldPassword || !newPassword || !confirmNewPassword)
            errors.push(t("Veuillez saisir tous les champs"));
        else {
            if (newPassword.length < 6)
                errors.push(t("Le mot de passe doit contenir au minimum 6 caractères"));
            if (newPassword !== confirmNewPassword)
                errors.push(t("Les deux mots de passe ne correspondent pas"));
        }

        if (errors.length > 0) {
            setPasswordErrors(errors);
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Mise à jour de votre mot de passe..."));

            axios.put("/user/", { id: user.id, oldPassword, newPassword, newPasswordConfirmation: confirmNewPassword }).then((response) => {
                toast.success(t("Votre mot de passe a bien été sauvegardé !"));
                setPasswordErrors([]);
                setOldPassword("");
                setNewPassword("");
                setConfirmNewPassword("");
            }).catch(err => {
                console.log(err);
                toast.error(t("Votre mot de passe n'a pas pu être sauvegardé !"));
                setPasswordErrors(err.response.data.errors);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const handleDeleteAccount = (e) => {
        e.preventDefault();
        setIsDeleteAccountPopupOpened(true);
    }

    const handleConfirmDeleteAccount = () => {
        setIsDeleteAccountPopupOpened(false);
        setIsLoading(true);
        setLoadingText(t("Suppression de votre compte..."));

        axios.post("/user/delete", { id: user.id }).then((response) => {
            logout();
        }).catch(err => {
            console.log(err);
            toast.error(t("Votre compte n'a pas pu être supprimé !"));
        }).finally(() => {
            setIsLoading(false);
        });

    }

    return (
        <div id="profile">
            <h1>{t("Profil")}</h1>

            <form>
                <fieldset id="infoUpdate">
                    <legend>{t("Vos informations")}</legend>

                    <FormField t={t} id="username" type="text" label={t("Pseudo")} value={username} onChange={setUsername} />
                    <FormField t={t} id="email" type="email" label={t("Email")} value={email} onChange={setEmail} />

                    {(infoErrors && infoErrors.length > 0) ? <ul className="formError">{infoErrors.map((error, index) => <li key={index}>{error}</li>)}</ul> : null}

                    <button className="btn thematized" onClick={handleSaveInfo} disabled={email === user.email && username === user.username}>{t("Sauvegarder mes informations")}</button>
                </fieldset>

                <fieldset id="passwordUpdate">
                    <legend>{t("Votre mot de passe")}</legend>

                    <FormField t={t} id="oldPassword" type="password" label={t("Ancien mot de passe")} value={oldPassword} onChange={setOldPassword} />
                    <FormField t={t} id="newPassword" type="password" label={t("Nouveau mot de passe")} value={newPassword} onChange={setNewPassword} />
                    <FormField t={t} id="confirmNewPassword" type="password" label={t("Confirmation")} value={confirmNewPassword} onChange={setConfirmNewPassword} />

                    {(passwordErrors && passwordErrors.length > 0) ? <ul className="formError">{passwordErrors.map((error, index) => <li key={index}>{error}</li>)}</ul> : null}

                    <button className="btn thematized" onClick={handleSavePassword} disabled={!oldPassword || !newPassword || !confirmNewPassword}>{t("Changer mon mot de passe")}</button>
                </fieldset>

                <button className="btn error" onClick={handleDeleteAccount}>{t("Supprimer le compte")}</button>
            </form>

            <ConfirmDeleteAccountPopup t={t} isOpened={isDeleteAccountPopupOpened} setIsOpened={setIsDeleteAccountPopupOpened} confirmDelete={handleConfirmDeleteAccount} />
        </div>
    )
}

export default Profile;

const ConfirmDeleteAccountPopup = ({ t, isOpened, setIsOpened, confirmDelete }) => {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);

    const handleConfirmDelete = (e) => {
        e.preventDefault();

        let error = null;
        if (!password)
            error = t("Veuillez saisir un mot de passe de confirmation");

        if (error) {
            setPasswordError(error);
        }
        else {
            axios.post("/user/confirmPassword", { password }).then((response) => {
                setPasswordError([]);
                confirmDelete();
            }).catch(err => {
                console.log(err);
                setPasswordError(err.response.data.error);
            });
        }
    }

    return (
        <Popup t={t} isOpened={isOpened} setIsOpened={setIsOpened} confirmAction={handleConfirmDelete} title={t("Suppression du compte")}>
            <>
                <p>{t("Attention ! Cette action est irréversible et mettra fin immédiatement à votre abonnement BSM !")}</p>
                <FormField t={t} type="password" id="confirmPasswordForDelete" label={t("Confirmation")} value={password} onChange={setPassword} />
                {(passwordError && passwordError.length > 0) ? <p className="formError">{passwordError}</p> : null}
            </>
        </Popup>
    );
}
