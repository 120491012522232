import React, { useState } from 'react';
import Select from 'react-select';
import { BsFillInfoCircleFill } from 'react-icons/bs';

function FormField({ t, type, id, value, label, className = "", placeholder, tooltip, afterLabel, onChange, multiple, step, min, max, disabled, setDisabled, checked = false, conditionable = false, options = [] }) {
    const [isDisabled, setIsDisabled] = useState(disabled);

    const handleUpdateDisable = (newValue) => {
        setIsDisabled(newValue);
        setDisabled(!newValue);
    }

    return (
        <div className={"formField " + className}>
            <label htmlFor={id}>
                {label}
                {tooltip ? <BsFillInfoCircleFill className="tooltipTrigger" id={tooltip} /> : null}
                {" "}:
            </label>
            {
                conditionable ?
                    <input type="checkbox" id={id + "-isActive"} className="conditionableField" checked={!isDisabled} onChange={e => handleUpdateDisable(!isDisabled)} />
                    : null
            }
            {
                type === "select" ?
                    (
                        multiple ?
                            (
                                <Select disabled={isDisabled} closeMenuOnSelect={false} name={id} id={id} onChange={(newValue, actionMeta) => {
                                    onChange(newValue.map(val => val.value));
                                }} isMulti options={options} defaultValue={value} />
                            ) : (
                                <select name={id} id={id} onChange={(e) => onChange(e.target.value)} value={value} disabled={isDisabled}>
                                    {
                                        options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)
                                    }
                                </select>
                            )
                    ) :
                    (
                        type === "number" ?
                            (
                                <input type={type} name={id} id={id} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} step={step} min={min} max={max} disabled={isDisabled} />
                            ) :
                            (
                                type === "checkbox" ?
                                    (
                                        <input type={type} name={id} id={id} value={value} placeholder={placeholder} onChange={(e) => onChange(!checked)} checked={checked} disabled={isDisabled} />
                                    ) :
                                    (
                                        type === "textarea" ?
                                            (
                                                <textarea name={id} id={id} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} value={value} rows="6" disabled={isDisabled}></textarea>
                                            ) :
                                            (
                                                <input type={type} name={id} id={id} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} disabled={isDisabled} />
                                            )
                                    )

                            )

                    )
            }
            {afterLabel}
        </div>
    )
}

export default FormField
