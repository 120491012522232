import React from 'react'

function ValueBetting({ t, Trans }) {
    return (
        <div id="valueBetting">
            <h2 id="concept">{t("Value betting : de quoi parle-t-on ?")}</h2>

            <p>{t("valueBetting_whatIsIt")}</p>

            <p className="danger">
                <img src="./images/attention.png" alt="attention" />
                {t("valueBetting_oddAndProbability")}
            </p>


            <h2 id="oddAndProbability">{t("Cote et probabilité")}</h2>

            <p>{t("valueBetting_oddAndProbability_1")}</p>

            <p className="formula">
                {t("P = 1 / C")}<br />
                [{t("ou")}]<br />
                {t("C = 1 / P")}
                <br /><br />
                <i>{t("où P est la probabilité de réalisation d'un événement")}</i><br />
                <i> {t("et C la cote proposée par le bookmaker")}</i>
            </p>


            <h2 id="realProbability">{t("Comment déterminer la probabilité réelle d'un événement ?")}</h2>
            <div className="contentContainer">
                <div className="content">
                    <p>{t("valueBetting_determineProbability_1")}</p>

                    <p>{t("valueBetting_determineProbability_2")}</p>
                    <ul>
                        <li>{t("valueBetting_determineProbability_3")}</li>
                        <li>{t("valueBetting_determineProbability_4")}</li>
                    </ul>

                    <p>{t("valueBetting_determineProbability_5")}</p>

                    <p>{t("valueBetting_determineProbability_6")}</p>
                </div>
                <img src="./images/real_probability_event.png" alt="best reference" />
            </div>

            <h2 id="booksMargin">{t("La marge des bookmakers")}</h2>
            <div className="contentContainer">
                <img src="./images/book_margin.png" id="bookMargin" alt="best reference" />
                <div className="content">
                    <p>{t("valueBetting_booksMargin_1")}</p>

                    <p>{t("valueBetting_booksMargin_2")}</p>
                </div>
            </div>

            <p>{t("valueBetting_booksMargin_3")} </p>

            <p className="formula">
                <strong>{t("MPTO")}</strong>
                <br /><br />
                {t("valueBetting_booksMargin_4")}
            </p>

            <p className="formula">
                <strong>{t("EM")}</strong>
                <br /><br />
                {t("valueBetting_booksMargin_5")}
            </p>

            <p>{t("valueBetting_booksMargin_6")}</p>
            <img src="./images/WTA_CLV_Pinnacle.png" alt="WTA CLV Pinnacle" />

            <p>{t("valueBetting_booksMargin_7")}</p>
            <ul>
                <li>{t("valueBetting_booksMargin_8")}</li>
                <li>{t("valueBetting_booksMargin_9")}</li>
            </ul>


            <h2 id="pinnacle">{t("Pinnacle : le baromètre le plus fiable")}</h2>
            <div className="contentContainer">
                <div className="content">
                    <p>{t("valueBatting_pinnacle_1")}</p>
                    <ul>
                        <li>{t("valueBatting_pinnacle_2")}</li>
                        <li>{t("valueBatting_pinnacle_3")}</li>
                        <li>{t("valueBatting_pinnacle_4")}</li>
                        <li>{t("valueBatting_pinnacle_5")}</li>
                        <li>{t("valueBatting_pinnacle_6")}</li>
                    </ul>

                    <p>{t("valueBatting_pinnacle_7")}</p>
                </div>
                <img src="./images/pinnacle_best.png" alt="best reference" />
            </div>


            <h2 id="CLV">{t("La notion de closing line value (CLV)")}</h2>

            <p>{t("valueBetting_CLV_1")}</p>

            <p>{t("valueBetting_CLV_2")}</p>

            <p>{t("valueBetting_CLV_3")}</p>
            <img src={t("valueBetting_CLV_resultsImage")} alt="results graph" />

            <p>{t("valueBetting_CLV_4")}</p>


            <h2 id="surebet">{t("Le surebetting")}</h2>

            <p>{t("valueBetting_surebetting_1")}</p>

            <p>{t("valueBetting_surebetting_2")}</p>

            <p>{t("valueBetting_surebetting_3")}</p>

            <p className="formula">
                {t("Cote \"pile\"")} = <strong className="odd1">{"2.11".replace(".", t("decimalSeparator"))}</strong><br />
                {t("Cote \"face\"")} = <strong className="odd2">{"1.98".replace(".", t("decimalSeparator"))}</strong><br />
                <br />
                --- <strong>{t("TRJ")} = {"102.2".replace(".", t("decimalSeparator"))}%</strong> ---<br />
                <br />
                100{t("€")} {t("misés répartis comme suit")} : <br />
                <strong className="odd1">{"48.4".replace(".", t("decimalSeparator"))}{t("€")}</strong> {t("sur la cote \"pile\"")}<br />
                <strong className="odd2">{"51.6".replace(".", t("decimalSeparator"))}{t("€")}</strong> {t("sur la cote \"face\"")}<br />
                <br />
                {t("Assurance de gain quel que soit le résultat")} : <br />
                <strong>{"2.2".replace(".", t("decimalSeparator"))}{t("€")}</strong>
            </p>

            <h2 id="limits">{t("Les limites des soft bookmakers")}</h2>
            <div className="contentContainer">
                <img src="./images/limitations.png" alt="best reference" />
                <div className="content">
                    <p>{t("valueBetting_booksLimits_1")}</p>

                    <p>{t("valueBetting_booksLimits_2")} </p>
                    <ul>
                        <li>{t("valueBetting_booksLimits_3")}</li>
                        <li>{t("valueBetting_booksLimits_4")}</li>
                        <li>{t("valueBetting_booksLimits_5")}</li>
                        <li>{t("valueBetting_booksLimits_6")}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ValueBetting
