import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CLVAndEVAndBKChart from './../components/CLVAndEVAndBKChart';
import SportsRepartitionChart from './../components/SportsRepartitionChart';
import BooksRepartitionChart from './../components/BooksRepartitionChart';
import BotParameters from './../components/BotParameters';
import FormField from '../components/FormField';
import Popup from '../components/Popup';
import toast from "react-hot-toast";
import MultipleFormField from '../components/MultipleFormField';
import { getDateForInput, formatBigNumber, isBetWon } from "../utils";

function BackTest({ t, isAxiosReady, hasActiveSubscription, setIsLoading, setLoadingText }) {
    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState();
    const [bets, setBets] = useState([]);
    const [isCreatePresetPopupOpened, setIsCreatePresetPopupOpened] = useState(false);

    const today = new Date();
    const startOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const [startPeriod, setStartPeriod] = useState(getDateForInput(startOfCurrentMonth));
    const [endPeriod, setEndPeriod] = useState(getDateForInput(endOfCurrentMonth));

    const presetsModelsOptions = presets.map(preset => { return { value: preset.id, label: preset.title ? preset.title : "Sans titre" } });

    useEffect(() => {
        if (isAxiosReady) {
            axios.get("/robot/all").then(response => {
                const robotsBySubscription = response.data;
                const allPresets = [];
                for (const [subscriptionName, presets] of Object.entries(robotsBySubscription)) {
                    presets.forEach(preset => {
                        preset.title = `${subscriptionName} - ${preset.title}`;
                        allPresets.push(preset);
                    });
                }

                setPresets(allPresets);
                setSelectedPreset(allPresets.find(preset => !("isDefaultPreset" in preset) || preset.isDefaultPreset === true));
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
    }, [isAxiosReady]);

    const handleParametersUpdate = (newParameters) => {
        if (hasActiveSubscription()) {
            const start = new Date(startPeriod);
            start.setHours(0, 0, 0, 0);
            const end = new Date(endPeriod);
            end.setHours(23, 59, 59, 0);

            newParameters = { ...newParameters, start: start.getTime(), end: end.getTime() };

            setIsLoading(true);
            setLoadingText(t("Chargement de l'échantillon de back-test..."));
            setSelectedPreset({ ...selectedPreset, ...newParameters });

            axios.post("/bet/backtest", newParameters).then(response => {
                setBets(response.data.filter(bet => "EVAtClosure" in bet && bet.EVAtClosure != null && bet.results !== "surrender" && bet.winner != null));
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
    }

    const handleSelectedPresetChange = (newIndex) => {
        setSelectedPreset(presets.find(preset => preset.id == newIndex));
    }

    return (
        <div id="backTest">
            {selectedPreset ? (
                <>
                    <h1>{t("Paramètres de la simulation")}</h1>

                    {hasActiveSubscription() ? null : <p className="error">{t("Vous devez être abonné pour utiliser ce module !")}</p>}

                    <MultipleFormField t={t} type={["date", "date"]} id={["startPeriod", "endPeriod"]} value={[startPeriod, endPeriod]} onChange={[setStartPeriod, setEndPeriod]} label={[t("Depuis le"), t("jusqu'au")]} placeholder={["", ""]} />
                    <FormField t={t} type="select" id="presetModel" value={selectedPreset.id} label={t("Modèle de preset")} options={presetsModelsOptions} onChange={handleSelectedPresetChange} />
                    <ParametersContainer t={t} isAxiosReady={isAxiosReady} presetId={selectedPreset.id} setIsLoading={setIsLoading} setLoadingText={setLoadingText} onParametersChange={handleParametersUpdate} setIsCreatePresetPopupOpened={setIsCreatePresetPopupOpened} />

                    <h1>{t("Résultat de la simulation")}</h1>

                    {
                        bets.length === 0 ?
                            <p className="noBetsFound">{t("Aucun pari trouvé pour cette simulation !")}</p>
                            : (
                                <>
                                    <KeyFiguresContainer t={t} bets={bets} capital={selectedPreset.capital} />

                                    <div className="pieChartsContainer">
                                        <BooksRepartitionChart t={t} bets={bets} />
                                        <SportsRepartitionChart t={t} bets={bets} />
                                    </div>

                                    <div className="graphsContainer">
                                        <CLVAndEVAndBKChart t={t} bets={bets} />
                                    </div>
                                </>
                            )
                    }

                    <CreatePresetPopup t={t} presets={presets} setPresets={setPresets} selectedPreset={selectedPreset} isCreatePresetPopupOpened={isCreatePresetPopupOpened} setIsCreatePresetPopupOpened={setIsCreatePresetPopupOpened} />
                </>
            ) : <p>{t("Veuillez créer un preset par défaut !")}</p>}
        </div>
    );
}

export default BackTest;

const ParametersContainer = ({ t, isAxiosReady, presetId, setIsLoading, setLoadingText, onParametersChange, setIsCreatePresetPopupOpened }) => {
    const onParametersInitiated = (initialParameters) => {
        onParametersChange(initialParameters);
    }

    const handleExportPresetClick = (e) => {
        e.preventDefault();
        setIsCreatePresetPopupOpened(true);
    }

    const additionallyButtons = [
        {
            "class": "classic",
            "action": handleExportPresetClick,
            "text": t("Exporter le preset")
        }
    ]

    return (
        <div className="parametersContainer">
            <BotParameters t={t} isAxiosReady={isAxiosReady} presetId={presetId} setIsLoading={setIsLoading} setLoadingText={setLoadingText} completePreset={false} executeButtonText="Simuler" handleExecute={onParametersChange} handleInitiated={onParametersInitiated} additionallyButtons={additionallyButtons} />
        </div>
    )
}

const KeyFiguresContainer = ({ t, bets, capital }) => {
    const [benefits, setBenefits] = useState(0);
    const [wonBets, setWonBets] = useState(0);
    const [totalBetPlayed, setTotalBetPlayed] = useState(0);
    const [mediumCLV, setMediumCLV] = useState(0);
    const [mediumOdd, setMediumOdd] = useState(0);
    const [mediumBet, setMediumBet] = useState(0);
    const [mediumCLVWeightedByLiquidities, setMediumCLVWeightedByLiquidities] = useState(0);

    useEffect(() => {
        let wonBets = 0;
        setBenefits(bets.length > 0 ? bets.reduce((sum, bet) => {
            const { hasWon, isRefunded, isNotFound, benefit } = isBetWon(bet);
            if (isNotFound || isRefunded) {
                return sum;
            } else {
                if (hasWon) {
                    wonBets++;
                }

                return sum + benefit;
            }
        }, 0) : 0);

        setWonBets(wonBets);

        let betsSum = bets.reduce((sum, bet) => sum + parseFloat(bet.bet), 0);
        setTotalBetPlayed(parseInt(bets.reduce((sum, bet) => sum + parseFloat(bet.bet), 0)));
        setMediumCLV(bets.reduce((sum, bet) => sum + parseFloat(bet.EVAtClosure) * parseFloat(bet.bet), 0) / betsSum);
        setMediumOdd(bets.reduce((sum, bet) => sum + parseFloat(bet.odd), 0) / bets.length);
        setMediumBet(bets.reduce((sum, bet) => sum + parseFloat(bet.bet), 0) / bets.length);

        let liquiditiesSum = 0;
        setMediumCLVWeightedByLiquidities(bets.reduce((sum, bet) => {
            if (bet.liquidities) {
                liquiditiesSum += parseInt(bet.liquidities);
                return sum + parseFloat(bet.EVAtClosure) * parseInt(bet.liquidities);
            } else {
                return sum;
            }
        }, 0) / liquiditiesSum);
    }, [bets]);

    return (
        <div className="keyFiguresContainer">
            <div className="keyFigure">
                <div className="container">
                    <div className="value">
                        {bets.length}
                        <small>({formatBigNumber(parseInt(mediumBet))}{t("€")})</small>
                    </div>
                    <div className="label">
                        {t("paris pris")}
                        <small>({t("mise moyenne")})</small>
                    </div>
                </div>
            </div>
            <div className="keyFigure">
                <div className="container">
                    <div className="value">
                        {(mediumCLV * 100).toFixed(2).replace(".", ",")}%
                        <small>({(mediumCLVWeightedByLiquidities * 100).toFixed(2).replace(".", ",")}%)</small>
                    </div>
                    <div className="label">
                        {t("CLV /mises")}
                        <small>({t("CLV /liquidités")})</small>
                    </div>
                </div>
            </div>
            <div className="keyFigure">
                <div className="container">
                    <div className="value">
                        {formatBigNumber(Math.round(benefits))}{t("€")}
                        <small>({formatBigNumber(totalBetPlayed).replace(".", ",")}{t("€")})</small>
                    </div>
                    <div className="label">
                        {t("bénéfices")}
                        <small>({t("total misé")})</small>
                    </div>
                </div>
            </div>
            <div className="keyFigure">
                <div className="container">
                    <div className="value">
                        {(benefits / totalBetPlayed * 100).toFixed(2).replace(".", ",")}%
                        <small>({(benefits / capital * 100).toFixed(2).replace(".", ",")}%)</small>
                    </div>
                    <div className="label">
                        {t("ROI")}
                        <small>({t("ROC")})</small>
                    </div>
                </div>
            </div>
            <div className="keyFigure">
                <div className="container">
                    <div className="value">
                        {parseFloat(mediumOdd).toFixed(2).replace(".", ",")}
                        <small>({parseInt(wonBets / bets.length * 100).toString().replace(".", ",")}%)</small>
                    </div>
                    <div className="label">
                        {t("Cote moyenne")}
                        <small>({t("taux de réussite")})</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreatePresetPopup = ({ t, presets, selectedPreset, setPresets, isCreatePresetPopupOpened, setIsCreatePresetPopupOpened }) => {
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();

    const handleCreatePreset = () => {
        const modelToDuplicate = { ...selectedPreset };

        // Prepare the new preset to create
        delete modelToDuplicate.id;
        Object.keys(modelToDuplicate).forEach(key => key.toLocaleLowerCase().includes("condition") ? delete modelToDuplicate[key] : null);
        modelToDuplicate.title = title;
        modelToDuplicate.description = description;
        modelToDuplicate.isDefaultPreset = false;

        axios.post("/robot", modelToDuplicate)
            .then(response => {
                setPresets([...presets, response.data]);
                toast.success(t("Le preset a bien été créé !"));
                setIsCreatePresetPopupOpened(false);
            }).catch(err => {
                console.log(err);
                toast.error(t("Le preset n'a pas pu être créé !"));
            });
    }

    return (
        <Popup t={t} title={t("Création de votre preset")} confirmAction={handleCreatePreset} isOpened={isCreatePresetPopupOpened} setIsOpened={setIsCreatePresetPopupOpened}>
            <FormField id="title" type="text" label={t("Titre")} value={title} onChange={setTitle} />
            <FormField id="description" type="textarea" label={t("Description")} value={description} onChange={setDescription} />
        </Popup>
    )
}