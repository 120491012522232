import React from 'react'
import { Link } from 'react-router-dom';

function PaymentSuccess({ t }) {
    return (
        <div id="paymentSuccess">
            <h1>{t("Paiement réussi !")}</h1>

            <p>{t("Votre abonnement sera activé d'ici 1 à 2 minutes !")}</p>
            <p>{t("Vous pouvez dès à présent cliquer")} <Link to="/presets">{t("ici")}</Link> {t("pour créer votre robot et pour le paramétrer avec vos propres critères afin de recevoir vos notifications personnalisées")} !</p>
        </div>
    )
}

export default PaymentSuccess
