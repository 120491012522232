import React from 'react'

function Tools({ t }) {
    return (
        <div id="tools">
			
        </div>
    )
}

export default Tools
