import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { stylizedBookName, isBetWon, getPrintableBet, formatBigNumber, getPrintableDate } from "../utils";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title);

function CLVAndEVAndBKChart({ t, bets }) {
    const EVs = [0];
    const EVsInPercents = [0];
    const CLVs = [0];
    const CLVsInPercents = [0];
    const reals = [0];

    let previousEVValue = 0;
    let previousCLVsValue = 0;
    let previousEVInPercentsValue = 0;
    let previousCLVsInPercentsValue = 0;
    let previousRealsValue = 0;

    bets.forEach(bet => {
        const newEVValue = previousEVValue + parseFloat(bet.EV) * parseFloat(bet.bet);
        const newCLVsValue = previousCLVsValue + parseFloat(bet.EVAtClosure) * parseFloat(bet.bet);
        const newEVInPercentsValue = previousEVInPercentsValue + parseFloat(bet.EV);
        const newCLVsInPercentsValue = previousCLVsInPercentsValue + parseFloat(bet.EVAtClosure);
        let newRealsValue = 0;

        const { hasWon, isRefunded, isNotFound, benefit } = isBetWon(bet);
        if (isRefunded || isNotFound) {
            newRealsValue = previousRealsValue;
        } else {
            newRealsValue = previousRealsValue + benefit;
        }

        EVs.push(newEVValue);
        CLVs.push(newCLVsValue);
        EVsInPercents.push(newEVInPercentsValue);
        CLVsInPercents.push(newCLVsInPercentsValue);
        reals.push(newRealsValue);

        previousEVValue = newEVValue;
        previousCLVsValue = newCLVsValue;
        previousEVInPercentsValue = newEVInPercentsValue;
        previousCLVsInPercentsValue = newCLVsInPercentsValue;
        previousRealsValue = newRealsValue;
    });

    const data = {
        labels: [t("Initial")].concat(bets.map((bet, index) => index + 1)),
        datasets: [
            {
                label: t("EV"),
                data: EVs,
                backgroundColor: "#ae2012",
                borderColor: "#ae2012"
            },
            {
                label: t("CLV"),
                data: CLVs,
                backgroundColor: "#ee9b00",
                borderColor: "#ee9b00"
            },
            {
                label: t("Perf réelle"),
                data: reals,
                backgroundColor: "#318001",
                borderColor: "#318001"
            }
        ]
    };

    const options = {
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            title: {
                display: true,
                text: t("Performances attendues et réalisées"),
                color: "#EBE9FC",
                font: {
                    size: 20,
                    family: "font-title"
                }
            },
            legend: {
                labels: {
                    color: "#EBE9FC"
                }
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        if (context[0].dataIndex === 0)
                            return t("Situation initiale");

                        const currentBet = bets[context[0].dataIndex - 1];

                        let toReturn = t("Match") + " : " + currentBet.homeTeamName + " vs. " + currentBet.awayTeamName;
                        toReturn += "\n" + t("Date") + " : " + t("le") + " " + getPrintableDate(currentBet.pinnacleClosureDate);

                        const betDone = getPrintableBet(currentBet.sport, currentBet.marketName, currentBet.side, currentBet.homeTeamName, currentBet.awayTeamName)

                        toReturn += "\n" + t("Pari") + " : " + betDone;
                        toReturn += "\n" + t("Book") + " : " + stylizedBookName[currentBet.bookName];

                        return toReturn;
                    },
                    label: function (context) {
                        if (context.dataIndex === 0)
                            return "";

                        let arrayToSearchIn = null;
                        if (context.dataset.label === t("EV")) arrayToSearchIn = EVsInPercents;
                        if (context.dataset.label === t("CLV")) arrayToSearchIn = CLVsInPercents;
                        if (context.dataset.label === t("Perf réelle")) arrayToSearchIn = reals;

                        const currentValue = context.dataIndex > 1 ? arrayToSearchIn[context.dataIndex] - arrayToSearchIn[context.dataIndex - 1] : arrayToSearchIn[context.dataIndex];

                        let toReturn = context.dataset.label + " : ";
                        if (context.dataset.label === t("Perf réelle"))
                            toReturn += Math.round(currentValue) + t("€") + " (" + t("Cumul.") + " : " + formatBigNumber(Math.round(arrayToSearchIn[context.dataIndex])) + t("€") + ")";
                        else
                            toReturn += (currentValue * 100).toFixed(2).replace(".", ",") + "% (" + t("Cumul.") + " : " + parseFloat(arrayToSearchIn[context.dataIndex] * 100).toFixed(2).replace(".", ",") + "%)";

                        return toReturn;
                    }
                }
            }
        },
        elements: {
            point: {
                hoverRadius: 4,
                radius: 1
            },
            line: {
                tension: 0.1
            }
        },
        scales: {
            x: {
                title: {
                    text: t("Nombre de paris pris et terminés"),
                    display: true,
                    color: "#EBE9FC"
                },
                grid: {
                    color: function (context) {
                        return "#4a4a4a";
                    }
                },
                ticks: {
                    color: "#EBE9FC"
                }
            },
            y: {
                title: {
                    text: t("Bénéfices cumulés"),
                    display: true,
                    color: "#EBE9FC"
                },
                ticks: {
                    color: "#EBE9FC",
                    callback: function (value, index, ticks) {
                        return value + t("€");
                    }
                },
                grid: {
                    lineWidth: function (context) {
                        if (context.tick.value === 0)
                            return 3;
                        else
                            return 1;
                    },
                    color: function (context) {
                        if (context.tick.value === 0)
                            return "black";
                        else
                            return "grey";
                    }
                }
            }
        }
    };

    return <div className="CLVAndEVAndBKChart"><Line data={data} options={options} /></div>
}

export default CLVAndEVAndBKChart;


