import React from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import BotParameters from '../components/BotParameters';
import toast from "react-hot-toast";
import { useParams } from 'react-router-dom';

function PresetDetails({ t, isAxiosReady, setIsLoading, setLoadingText }) {
    const { id } = useParams();

    const handleSaveParams = (newParams) => {
        axios.put("/robot/", newParams).then((response) => {
            toast.success(t("Vos paramètres ont bien été sauvegardés !"));
            return true;
        }).catch(err => {
            console.log(err);
            toast.error(t("Vos paramètres n'ont pas pu être sauvegardés !"));
            return false;
        });
    }

    useEffect(() => {
        setIsLoading(true);
        setLoadingText(t("Chargement des paramètres du robot..."));
    }, [])

    const onParametersInitiated = (initialParameters) => {
        setIsLoading(false);
    }

    return (
        <div id="presetDetails">
            <h1>{t("Gestion de votre preset")}</h1>

            {
                isAxiosReady && <BotParameters t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} presetId={id} completePreset={true} executeButtonText={t("Sauvegarder")} handleInitiated={onParametersInitiated} handleExecute={handleSaveParams} />
            }
        </div >
    )
}

export default PresetDetails;